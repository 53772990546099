import { DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { FbAdAccountStatus } from 'core/fbAdAccount/FbAdAccount';
import { FbAdSet, FbBillingEvent } from 'core/fbAdSet/FbAdSet';
import { DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { FbObjective } from 'core/fbCampaign/FbCampaign';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { MessageCampaign } from 'core/messageCampaign/MessageCampaign';
import { DefaultMessageCampaignManager } from 'core/messageCampaign/MessageCampaignManager';
import { Order } from 'core/order/Order';
import { CampaignState, RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import _, { defaultTo } from 'lodash';
import moment from 'moment';
import { validateEmpty } from 'utils/ValidateUtils';
import { L1ObjectFormChannelModel } from './L1ObjectFormChannelModel';

export abstract class L1ObjectEditFormChannelModel implements L1ObjectFormChannelModel {

  minBudgetPerDay: number = 0;
  minBudgetPerCampaign: number = 0;
  constructor (
    protected order: Order,
    public l1Object: L1Object,
    protected l1ObjectManager = new DefaultL1ObjectManager()
  ) {
    this.minBudgetPerDay = order.campaignConstraint.budgetMinimum;
    this.minBudgetPerCampaign = order.campaignConstraint.campaignBudgetMinimum;
  }

  abstract minBudget (values: Partial<L1Object>);

  abstract get totalBudget ();

  abstract init (callback: (data) => void);

  abstract validate (value: Partial<L1Object>);

  abstract getCboChangeHint ();

  abstract getDefaultBidStrategy ();

  abstract getBudgetManagementModalData (
    values: Partial<L1Object>,
    valueUpdater: (updateFunc: (prevValue: any) => any) => void
  ): any;

  abstract onCBOChange (enable: boolean, valueUpdater: (updateFunc: (prevValue: any) => any) => void, showManagementModal: () => void);

  abstract showRemainBudget (values: Partial<L1Object>);

  abstract toChannelNativeData (l1Object: L1Object, extraData: any): any;
}

export class FBCampaignEditFormModel extends L1ObjectEditFormChannelModel {

  fbAdAccountOptions?: SelectOptions[];
  minBudgetFromServer: number = 0;

  constructor (
    order: Order,
    l1Object: L1Object,
    private fbAdSets?: FbAdSet[],
    private advertiserManager = new DefaultAdvertiserManager(),
    private fbAdSetManager = new DefaultFbAdSetManager()
  ) {
    super(order, l1Object);
  }

  minBudget () {
    const order = this.order;
    const l1Object = this.l1Object;
    const fbAdSets = this.fbAdSets;
    if (!l1Object) {
      return 0;
    }
    const currencyRate = _.get(l1Object, 'currencyRate', 1);
    if (!fbAdSets || fbAdSets.length === 0) {
      const minBudget = this.fbAdSetManager.getMinBudgetOfFBObject(
        _.defaultTo(currencyRate, 1),
        1,
        BidStrategy.LOWEST_COST_WITHOUT_CAP,
        FbBillingEvent.IMPRESSIONS,
        1,
        this.order
      );
      return Math.max(minBudget, this.minBudgetFromServer / (1 - order.orderMargin - order.sysMargin));
    } else {
      const minBudgetOfFbAdSets = fbAdSets.reduce((acc, fbAdSet) => {
        const startDateMoment = moment(fbAdSet.start_time);
        const endDateMoment = moment(fbAdSet.end_time);
        const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
        const bidStrategy: string | undefined = _.get(l1Object, 'fb.bid_strategy');
        return acc + Math.max(
          this.fbAdSetManager.getMinBudgetOfFBObject(
            currencyRate,
            +_.defaultTo(fbAdSet.bid_amount, 0),
            bidStrategy ? bidStrategy as BidStrategy : fbAdSet.bid_strategy,
            fbAdSet.billing_event,
            scheduleDateCount,
            order
          ),
          +_.defaultTo(fbAdSet.lifetime_min_spend_target, 0)
        );
      }, 0);
      return Math.max(minBudgetOfFbAdSets, this.minBudgetFromServer / (1 - order.orderMargin - order.sysMargin));
    }
  }

  get totalBudget () {
    if (!this.l1Object || !this.fbAdSets) {
      return 0;
    }
    let l1ObjectBudget = 0;
    if (_.get(this.l1Object, 'budget') !== '' && _.get(this.l1Object, 'budget') !== undefined) {
      l1ObjectBudget = +_.defaultTo(_.get(this.l1Object, 'budget'), 0).toString();
    } else {
      l1ObjectBudget = this.fbAdSets.reduce((acc, fbAdSet) => {
        return fbAdSet.lifetime_budget ? acc + (+fbAdSet.lifetime_budget) : acc;
      }, 0);
    }
    return _.defaultTo(_.get(this.order, 'budgetBalance'), 0) + l1ObjectBudget;
  }

  init = async (callback: (data) => void) => {
    if (!this.fbAdAccountOptions) {
      const fbAdAccounts = await this.advertiserManager.getAdvertiserFBAdAccounts(this.order.advertiserId);
      this.fbAdAccountOptions = fbAdAccounts.filter(account => account.status === FbAdAccountStatus.ACTIVE)
                                  .map(account => ({
                                    label: account.name,
                                    value: account.fbAdAccountId,
                                    extra: {
                                      hasAdvertiserOptedInOdax: account.hasAdvertiserOptedInOdax
                                    }
                                  }));
    }
    this.minBudgetFromServer = await this.l1ObjectManager.getFBCampaignMinBudget(this.l1Object.l1ObjectId);
    if (!this.fbAdSets) {
      this.fbAdSets = await this.fbAdSetManager.getAdSets(this.l1Object.l1ObjectId);
    }
    callback({
      fbAdAccountOptions: this.fbAdAccountOptions,
      l2ObjectList: this.fbAdSets
    });
  }

  validate = (value: Partial<L1Object>) => {
    return {
      fb: _.omitBy({
        account_id: validateEmpty(_.get(value, 'fb.account_id'))
      }, _.isEmpty)
    };
  }

  getCboChangeHint = () => {
    if (this.l1Object && this.l1Object.autoOptimise && (this.fbAdSets && this.fbAdSets.length === 0)) {
      return i18n.t<string>('l1Object.labels.cboFbAdSetLengthLimit');
    }
    const cboOpenLimit = this.getCboOpenLimit();
    if (cboOpenLimit && !this.l1Object.autoOptimise) {
      return cboOpenLimit;
    }
    const lastBudgetTogglingTime: string = _.get(this.l1Object, 'fb.last_budget_toggling_time', '');
    if (lastBudgetTogglingTime) {
      const localTime = lastBudgetTogglingTime.replace('+0800', '');
      const minutes = moment(localTime).add(2, 'hours').diff(moment(), 'minutes');
      if (minutes > 0) {
        return i18n.t<string>('l1Object.labels.cboTimeLimit', { time: minutes });
      }
    }
  }

  getCboOpenLimit () {
    if (!this.fbAdSets || this.fbAdSets.length === 0) {
      return;
    }
    const optimizationGoals: L2ObjectOptimizationGoal[] = [];
    const bidStrategies: BidStrategy[] = [];
    this.fbAdSets.forEach(fbAdSet => {
      optimizationGoals.push(fbAdSet.optimization_goal);
      bidStrategies.push(fbAdSet.bid_strategy);
    });
    if (_.uniq(optimizationGoals).length > 1 || _.uniq(bidStrategies).length > 1) {
      return i18n.t<string>('l1Object.labels.fbCboLimitation');
    }
  }

  getDefaultBidStrategy () {
    if (!this.fbAdSets || this.fbAdSets.length === 0) {
      return BidStrategy.LOWEST_COST_WITHOUT_CAP;
    }
    return this.fbAdSets[0].bid_strategy ? this.fbAdSets[0].bid_strategy : BidStrategy.LOWEST_COST_WITHOUT_CAP;
  }

  getBudgetManagementModalData (
    values: Partial<L1Object>,
    valuesUpdater: (updateFunc: (prevValue: any) => any) => void
  ) {
    const adSetBudgets: any[] = _.get(values, 'fb.adset_budgets', []);
    return {
      channel: L1ObjectChannel.FB,
      budgetDataList: adSetBudgets ? adSetBudgets.map(adsetBudget => ({
        id: adsetBudget.adset_id,
        budget: adsetBudget.lifetime_budget
      })) : undefined,
      order: this.order,
      l1Object: values,
      initL1Object: this.l1Object,
      l2ObjectList: this.fbAdSets,
      onSubmit: (budgetMap) => {
        valuesUpdater(prev => ({
          ...prev,
          autoOptimise: false,
          budget: '',
          fb: {
            ...defaultTo(prev.fb, {}),
            bid_strategy: undefined,
            adset_budgets: Object.keys(budgetMap).map(id => ({
              adset_id: id,
              lifetime_budget: budgetMap[id]
            }))
          }
        }));
      },
      onCancel: () => {
        if (!_.get(values, 'fb.adset_budgets')) {
          valuesUpdater(prev => ({
            ...prev,
            autoOptimise: true
          }));
        }
      }
    };
  }

  onCBOChange (enable: boolean, valueUpdater: (updateFunc: (prevValue: any) => any) => void, showManagementModal: () => void) {
    if (enable) {
      const defaultBudget = this.fbAdSets ? this.fbAdSets.reduce((acc, fbAdSet) => {
        return acc + +_.defaultTo(fbAdSet.lifetime_budget, 0);
      }, 0) : 0;
      valueUpdater(prev => ({
        ...prev,
        budget: defaultBudget,
        fb: {
          ...defaultTo(prev.fb, {}),
          bid_strategy: this.getDefaultBidStrategy(),
          adset_budgets: undefined
        }
      }));
    } else {
      if (this.fbAdSets && this.fbAdSets.length > 0 && _.get(this.l1Object, 'autoOptimise')) {
        showManagementModal();
      } else {
        valueUpdater(prev => ({
          ...prev,
          budget: _.get(this.l1Object, 'budget', prev.budget),
          fb: {
            ...defaultTo(prev.fb, {}),
            bid_strategy: undefined
          }
        }));
      }
    }
  }

  showRemainBudget (values: Partial<L1Object>) {
    const isCBO = _.get(values, 'autoOptimise', false);
    return isCBO;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    // const accountId = _.get(l1Object.fb, 'account_id', '').toString();
    // const fbAdAccountOption = extraData.fbAdAccountOptions.find(fbAdAccount => fbAdAccount.value.toString() === accountId);
    const pmax3Objective = _.get(l1Object.fb, 'objective');
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.FB, pmax3Objective);
    const fb = l1Object.fb ? _.omit(
      {
        ...l1Object.fb,
        lifetime_budget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
        bid_strategy: l1Object.fb.bid_strategy ? l1Object.fb.bid_strategy : null,
        objective
      }, ['createdTime', 'ctime', 'updatedTime', 'utime', 'lifetime_budget']
    ) : undefined;
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      fb
    };
  }

  turnToFbLegecyObjective = (objecive) => {
    switch (objecive) {
      case FbObjective.OUTCOME_AWARENESS:
        return FbObjective.REACH;
      case FbObjective.OUTCOME_TRAFFIC:
        return FbObjective.LINK_CLICKS;
      case FbObjective.OUTCOME_SALES:
        return FbObjective.CONVERSIONS;
    }
  }
}

export class RTBCampaignGroupEditFormModel extends L1ObjectEditFormChannelModel {

  campaignsWithDailyBudget: RtbCampaignListBasic[];

  constructor (
    order: Order,
    l1Object: L1Object,
    private rtbCampaigns?: RtbCampaignListBasic[],
    private campaignManager = new DefaultRtbCampaignManager()
  ) {
    super(order, l1Object);
    this.campaignsWithDailyBudget = [];
  }

  minBudget (values: Partial<L1Object>) {
    const order = this.order;
    // 查看編輯當下選擇的 CBO 狀態
    const isCBO: boolean = _.get(values, 'autoOptimise', false);
    const managementBudgets = _.get(values, 'rtb.campaign_budgets', []);
    // CBO 時 inactive 的廣告活動就略過預算檢查
    const ignoredCampaignStates = isCBO
     ? [CampaignState.DELETE, CampaignState.DEACTIVATE]
     : [CampaignState.DELETE];
    const validRtbCampaigns = this.rtbCampaigns
     ? this.rtbCampaigns.filter(campaign => !_.includes(ignoredCampaignStates, campaign.state))
     : [];
    if (validRtbCampaigns.length === 0) {
      return this.minBudgetPerDay;
    } else {
      const minBudgetOfRtbCampaign = validRtbCampaigns.reduce((acc, rtbCampaign) => {
        // CBO 時的廣告活動檢查邏輯為：考慮廣告活動的日預算 * 天數
        // 非 CBO 時的廣告活動檢查邏輯為：考慮廣告活動的預算
        const managementBudget = managementBudgets.find(campaign => campaign.id === rtbCampaign.id?.toString());
        const campaignBudget = managementBudget ? managementBudget.budget : rtbCampaign.budget;
        const minBudget = isCBO
         ? Math.max(this.campaignManager.getMinBudgetOfCampaign(rtbCampaign, this.minBudgetPerDay), rtbCampaign.expectedSpent)
         : campaignBudget;
        return acc + minBudget;
      }, 0);
      return Math.min(order.budget, getPriceValue(order.currency, minBudgetOfRtbCampaign));
    }
  }

  get totalBudget () {
    if (!this.l1Object || !this.rtbCampaigns) {
      return 0;
    }
    let l1ObjectBudget = 0;
    const currentFieldValue = _.get(this.l1Object, 'budget');
    if (currentFieldValue !== '' && currentFieldValue !== undefined) {
      l1ObjectBudget = +_.defaultTo(currentFieldValue, 0).toString();
    } else {
      l1ObjectBudget = this.rtbCampaigns.reduce((acc, rtbCampaign) => {
        return rtbCampaign.budget ? acc + (+rtbCampaign.budget) : acc;
      }, 0);
    }
    return _.defaultTo(_.get(this.order, 'budgetBalance'), 0) + l1ObjectBudget;
  }

  init = async (callback: (data) => void) => {
    if (!this.rtbCampaigns) {
      this.rtbCampaigns = await this.campaignManager.getCampaignsOfGroup(this.l1Object.l1ObjectId);
    }
    this.campaignsWithDailyBudget = await this.campaignManager.getRtbCampaignsUsingDailyBudget(this.l1Object.l1ObjectId);
    callback({
      l2ObjectList: this.rtbCampaigns
    });
  }

  validate = (value: Partial<L1Object>) => {
    // 查看編輯當下選擇的 CBO 狀態
    const isCBO: boolean = _.get(value, 'autoOptimise', false);
    const isSwitchingCBO: boolean = isCBO !== this.l1Object.autoOptimise;
    if (isSwitchingCBO && isCBO) {
      // 從非 CBO 切換到 CBO 時，檢查底下廣告活動預算是否大於日預算 * 天數
      const invalidRtbCampaignIds = this.rtbCampaigns ?
      this.rtbCampaigns.filter(campaign => {
        const minBudget = this.campaignManager.getMinBudgetOfCampaign(campaign, this.minBudgetPerDay);
        return campaign.budget < minBudget;
       }).map(campaign => campaign.id) : [];
      if (invalidRtbCampaignIds.length > 0) {
        return {
          autoOptimise: i18n.t<string>('l1Object.labels.cboRtbCampaignBudgetLimit', {
            campaignIds: invalidRtbCampaignIds.join(', ')
          })
        };
      }
    }
    return {};
  }

  getCboChangeHint = () => {
    if (this.l1Object && this.l1Object.autoOptimise && (this.rtbCampaigns && this.rtbCampaigns.length === 0)) {
      return i18n.t<string>('l1Object.labels.cboRtbCampaignLengthLimit');
    }
    const cboOpenLimit = this.getCboOpenLimit();
    if (cboOpenLimit && !this.l1Object.autoOptimise) {
      return cboOpenLimit;
    }

    const lastBudgetTogglingTime = _.get(this.l1Object, 'rtb.update_cbo_time');
    if (lastBudgetTogglingTime) {
      const minutes = moment(lastBudgetTogglingTime).add(2, 'hours').diff(moment(), 'minutes');
      if (minutes > 0) {
        return i18n.t<string>('l1Object.labels.cboTimeLimit', { time: minutes });
      }
    }
  }

  getCboOpenLimit () {
    if (!this.rtbCampaigns || this.rtbCampaigns.length === 0) {
      return;
    }
    const optimizationGoals: L2ObjectOptimizationGoal[] = [];
    const bidStrategies: BidStrategy[] = [];
    this.rtbCampaigns.forEach(rtbCampaign => {
      optimizationGoals.push(rtbCampaign.optimize);
      bidStrategies.push(_.isNumber(rtbCampaign.bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP);
    });
    if (_.uniq(optimizationGoals).length > 1 || _.uniq(bidStrategies).length > 1) {
      return i18n.t<string>('l1Object.labels.rtbCboLimitation');
    }
    if (this.campaignsWithDailyBudget && this.campaignsWithDailyBudget.length > 0) {
      return i18n.t<string>('l1Object.labels.rtbCboDailyBudgetHint');
    }
  }

  getDefaultBidStrategy () {
    if (!this.rtbCampaigns || this.rtbCampaigns.length === 0) {
      return BidStrategy.LOWEST_COST_WITHOUT_CAP;
    }
    return _.isNumber(this.rtbCampaigns[0].bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP;
  }

  getBudgetManagementModalData (
    values: Partial<L1Object>,
    valuesUpdater: (updateFunc: (prevValue: any) => any) => void
  ) {
    return {
      channel: this.l1Object.channel,
      budgetDataList: _.get(values, 'rtb.campaign_budgets'),
      order: this.order,
      l1Object: values,
      initL1Object: this.l1Object,
      l2ObjectList: this.rtbCampaigns,
      onSubmit: (budgetMap) => {
        valuesUpdater(prev => ({
          ...prev,
          autoOptimise: false,
          rtb: {
            ...defaultTo(prev.rtb, {}),
            bid_strategy: undefined,
            campaign_budgets: Object.keys(budgetMap).map(id => ({
              id: id,
              budget: budgetMap[id]
            }))
          }
        }));
      },
      onCancel: () => {
        if (!_.get(values, 'rtb.campaign_budgets')) {
          valuesUpdater(prev => ({
            ...prev,
            autoOptimise: true
          }));
        }
      }
    };
  }

  onCBOChange (enable: boolean, valuesUpdater: (updateFunc: (prevValue: any) => any) => void, showManagementModal: () => void) {
    if (enable) {
      const defaultBudget = this.rtbCampaigns ? this.rtbCampaigns.reduce((acc, rtbCampaign) => {
        return acc + Math.max(
          +_.defaultTo(rtbCampaign.budget, 0),
          this.campaignManager.getMinBudgetOfCampaign(rtbCampaign, this.minBudgetPerDay)
        );
      }, 0) : 0;
      valuesUpdater(prev => ({
        ...prev,
        budget: defaultBudget,
        rtb: {
          ...defaultTo(prev.rtb, {}),
          bid_strategy: this.getDefaultBidStrategy(),
          campaign_budgets: undefined
        }
      }));
    } else {
      if (this.rtbCampaigns && this.rtbCampaigns.length > 0 && _.get(this.l1Object, 'autoOptimise')) {
        showManagementModal();
      } else {
        valuesUpdater(prev => ({
          ...prev,
          budget: _.get(this.l1Object, 'budget', prev.budget),
          rtb: {
            ...defaultTo(prev.rtb, {}),
            bid_strategy: undefined
          }
        }));
      }
    }
  }

  showRemainBudget (values: Partial<L1Object>) {
    return true;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const rtb = l1Object.rtb ? {
      ...l1Object.rtb,
      bid_strategy: l1Object.rtb.bid_strategy ? l1Object.rtb.bid_strategy : null
    } : undefined;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.RTB, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      rtb
    };
  }
}

export class EdiMaxCampaignGroupEditFormModel extends RTBCampaignGroupEditFormModel {

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const rtb = l1Object.rtb ? {
      ...l1Object.rtb,
      bid_strategy: l1Object.rtb.bid_strategy ? l1Object.rtb.bid_strategy : null
    } : undefined;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.EDIMAX, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      rtb
    };
  }
}

export class PICCampaignGroupEditFormModel extends RTBCampaignGroupEditFormModel {

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const rtb = l1Object.rtb ? {
      ...l1Object.rtb,
      bid_strategy: l1Object.rtb.bid_strategy ? l1Object.rtb.bid_strategy : null
    } : undefined;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.PIC, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      rtb
    };
  }
}

export class MessageCampaignGroupEditFormModel extends L1ObjectEditFormChannelModel {
  getCboChangeHint () {
    return;
  }

  constructor (
    order: Order,
    l1Object: L1Object,
    private messageCampaigns?: MessageCampaign[],
    private messageCampaignManager = new DefaultMessageCampaignManager()
  ) {
    super(order, l1Object);
  }

  minBudget (values: Partial<L1Object>) {
    const order = this.order;
    const validRtbCampaigns = this.messageCampaigns
     ? this.messageCampaigns.filter(data => !_.includes([CampaignState.DELETE], data.basic.state))
     : [];
    if (validRtbCampaigns.length === 0) {
      return 0;
    } else {
      const minBudgetOfRtbCampaign = validRtbCampaigns.reduce((acc, data) => acc + data.basic.budget, 0);
      return Math.min(order.budget, getPriceValue(order.currency, minBudgetOfRtbCampaign));
    }
  }

  get totalBudget () {
    if (!this.l1Object || !this.messageCampaigns) {
      return 0;
    }
    const currentFieldValue = _.get(this.l1Object, 'budget');
    let l1ObjectBudget = +_.defaultTo(currentFieldValue, 0).toString();
    return _.defaultTo(_.get(this.order, 'budgetBalance'), 0) + l1ObjectBudget;
  }

  init = async (callback: (data) => void) => {
    if (!this.messageCampaigns) {
      this.messageCampaigns = await this.messageCampaignManager.getCampaignsOfGroup(this.l1Object.l1ObjectId);
    }
    callback({
      l2ObjectList: this.messageCampaigns
    });
  }

  validate = (value: Partial<L1Object>) => {
    return {};
  }

  getDefaultBidStrategy () {
    return BidStrategy.LOWEST_COST_WITHOUT_CAP;
  }

  getBudgetManagementModalData (
    values: Partial<L1Object>,
    valuesUpdater: (updateFunc: (prevValue: any) => any) => void
  ) {
    return {
      channel: this.l1Object.channel,
      budgetDataList: _.get(values, 'message.campaign_budgets'),
      order: this.order,
      l1Object: values,
      initL1Object: this.l1Object,
      l2ObjectList: this.messageCampaigns,
      onSubmit: (budgetMap) => {
        valuesUpdater(prev => ({
          ...prev,
          autoOptimise: false,
          rtb: {
            ...defaultTo(prev.rtb, {}),
            bid_strategy: undefined,
            campaign_budgets: Object.keys(budgetMap).map(id => ({
              id: id,
              budget: budgetMap[id]
            }))
          }
        }));
      },
      onCancel: () => {
        if (!_.get(values, 'message.campaign_budgets')) {
          valuesUpdater(prev => ({
            ...prev,
            autoOptimise: true
          }));
        }
      }
    };
  }

  onCBOChange (enable: boolean, valuesUpdater: (updateFunc: (prevValue: any) => any) => void, showManagementModal: () => void) {
  }

  showRemainBudget (values: Partial<L1Object>) {
    return true;
  }

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const rtb = l1Object.rtb ? {
      ...l1Object.rtb,
      bid_strategy: l1Object.rtb.bid_strategy ? l1Object.rtb.bid_strategy : null
    } : undefined;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.RTB, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      rtb
    };
  }
}

export class HamiVideoCampaignGroupEditFormModel extends RTBCampaignGroupEditFormModel {

  toChannelNativeData (l1Object: L1Object, extraData: any) {
    const rtb = l1Object.rtb ? {
      ...l1Object.rtb,
      bid_strategy: l1Object.rtb.bid_strategy ? l1Object.rtb.bid_strategy : null
    } : undefined;
    const pmax3Objective = l1Object.objective;
    const objective = this.l1ObjectManager.getNativeObjectiveValue(L1ObjectChannel.HAMI_VIDEO, pmax3Objective);
    return {
      ...l1Object,
      objective,
      l1ObjectLifetimeBudget: _.isNil(l1Object.budget) ? 0 : l1Object.budget,
      rtb
    };
  }
}
