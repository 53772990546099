import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { useCoreContext } from 'contexts/coreContext';
import { AddonFeatureManager } from 'core';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { BidStrategy } from 'core/l2Object/L2Object';
import { MessageCampaign } from 'core/messageCampaign/MessageCampaign';
import { Order } from 'core/order/Order';
import { RtbCampaignListBasic } from 'core/rtbCampaign/RtbCampaign';
import { getPriceValue } from 'helper/CurrencyHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import i18n from 'i18n';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { validateEmpty, validateMinMax } from 'utils/ValidateUtils';
import {
  EdiMaxCampaignGroupCreateFormModel,
  FBCampaignCreateFormModel,
  HamiVideoCampaignGroupCreateFormModel,
  L1ObjectCreateFormChannelModel,
  MessageCampaignGroupCreateFormModel,
  PICCampaignGroupCreateFormModel,
  RTBCampaignGroupCreateFormModel
} from './L1ObjectCreateFormChannelModel';
import {
  EdiMaxCampaignGroupEditFormModel,
  FBCampaignEditFormModel,
  HamiVideoCampaignGroupEditFormModel,
  L1ObjectEditFormChannelModel,
  MessageCampaignGroupEditFormModel,
  PICCampaignGroupEditFormModel,
  RTBCampaignGroupEditFormModel
} from './L1ObjectEditFormChannelModel';

const defaultL1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager();
const defaultFbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager();
const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();

type ChannelRelatedData = {
  totalBudget: number;
  validate: (values: Partial<L1Object>) => any;
  onCBOChange: (values: Partial<L1Object>, valueUpdater: (updateFunc: (prevValue: any) => any) => void, enable: boolean) => void;
  showManagementModal: (
    values: Partial<L1Object>,
    valuesUpdater: (updateFunc: (prevValue: any) => any) => void
  ) => any,
  showRemainBudget: (values: Partial<L1Object>) => boolean;
  getCboChangeHint: (l1Object: Partial<L1Object>) => string | undefined;
  getDefaultBidStrategy: () => BidStrategy;
  save: (values: Partial<L1Object>) => Promise<void>;
};

export type L1ObjectFormData = {
  channelModel?: L1ObjectCreateFormChannelModel | L1ObjectEditFormChannelModel;
  formType: string;
  title: string;
  loading: boolean;
  initL1Object?: Partial<L1Object>;
  l1ObjectTypeOptions: SelectOptions[];
  objectiveOptions: SelectOptions[];
  redirectData?: {
    pathname: string,
    state?: any
  };
  currentUrl: string;
  cancelTargetPath: string;
  order: Order;
  l2ObjectList: FbAdSet[] | RtbCampaignListBasic[],
  fbAdAccountOptions: SelectOptions[];
  canEditBidStrategy: boolean;
  budgetManagementModalData: any,
  breadcrumbs: any[],
  closeManagementModal: () => void,
  onChannelChange: (valueUpdater: (updateFunc: (prevValue: any) => any) => void, channel: L1ObjectChannel) => void;
} & ChannelRelatedData;

export const useCreateL1ObjectFormModel = (
  order: Order,
  addonFeatureManager: AddonFeatureManager,
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager
): L1ObjectFormData => {

  const allowedChannels = l1ObjectManager.getAllowedChannels(addonFeatureManager);
  const initL1ObjectChannel = !_.isEmpty(allowedChannels) ? L1ObjectChannel[allowedChannels[0]] : L1ObjectChannel.RTB;
  const useViewableImpressInReport = addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);

  const core = useCoreContext();
  const currencyRate = _.get(core, 'accountManager.localeMeta.currencyRate', 1);
  const channelModelsMap = useMemo(() => ({
    [L1ObjectChannel.RTB]: new RTBCampaignGroupCreateFormModel(order, useViewableImpressInReport),
    [L1ObjectChannel.FB]: new FBCampaignCreateFormModel(order, currencyRate),
    [L1ObjectChannel.RETAIL_MEDIA]: new RTBCampaignGroupCreateFormModel(order, useViewableImpressInReport),
    [L1ObjectChannel.EDIMAX]: new EdiMaxCampaignGroupCreateFormModel(order, useViewableImpressInReport),
    [L1ObjectChannel.PIC]: new PICCampaignGroupCreateFormModel(order, useViewableImpressInReport),
    [L1ObjectChannel.MESSAGE]: new MessageCampaignGroupCreateFormModel(order, useViewableImpressInReport),
    [L1ObjectChannel.HAMI_VIDEO]: new HamiVideoCampaignGroupCreateFormModel(order, useViewableImpressInReport)
  }), [currencyRate, order, useViewableImpressInReport]);
  const initChannelModel: L1ObjectCreateFormChannelModel = initL1ObjectChannel in channelModelsMap ?
    channelModelsMap[initL1ObjectChannel] :
    channelModelsMap[L1ObjectChannel.RTB];
  let initL1Object = {
    name: '',
    channel: initL1ObjectChannel,
    budget: 0,
    autoOptimise: false,
    budgetBalance: order.budgetBalance,
    objective: L1ObjectObjective.AWARENESS
  };

  initChannelModel.initChannelData((undateFunc: (prevValue: any) => any) => {
    initL1Object = undateFunc(initL1Object);
  });

  const { loading, callAPIs } = useCallAPI();
  const [channelType, setChannelType] = useState<L1ObjectChannel>(initL1Object.channel);
  const [fbAdAccountOptions, setFbAdAccountOptions] = useState<SelectOptions[]>([]);
  const [redirectData, setRedirectData] = useState<{
    pathname: string,
    state?: any
  } | undefined>(undefined);

  const match = useRouteMatch();
  const currentUrl = match.url;
  const orderDetailPath = getRedirectPath(currentUrl);
  const l1ObjectTypeOptions = useMemo(() => {
    return defaultL1ObjectManager.getL1ObjectChannelOptions()
      .filter((option: SelectOptions) => {
        return addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL[option.value]);
      });
  }, [addonFeatureManager]);
  const objectiveOptions = useMemo(() => {
    return defaultL1ObjectManager.getL1ObjectObjectiveOptions(channelType);
  }, [channelType]);

  const [channelModel, setChannelModel] = useState<L1ObjectCreateFormChannelModel>(initChannelModel);

  const onChannelChange = (valueUpdater: (updateFunc: (prevValue: any) => any) => void, channel: L1ObjectChannel) => {
    const channelModel = channel in channelModelsMap ?
      channelModelsMap[channel] :
      channelModelsMap[L1ObjectChannel.RTB];
    setChannelType(channel);
    setChannelModel(channelModel);
    channelModel.initChannelData(valueUpdater);
  };

  useEffect(() => {
    callAPIs([
      async () => channelModel.init((options) => {
        if (channelType === L1ObjectChannel.FB) {
          setFbAdAccountOptions(options);
        }
      })
    ], () => {
      if (_.isEmpty(l1ObjectTypeOptions)) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/error404`;
        setRedirectData({
          pathname: redirectPath
        });
        return;
      }
    });
  }, [order, channelModel, callAPIs, channelType, l1ObjectTypeOptions]);

  const save = async (saveValue) => {
    const extraData = {
      order,
      fbAdAccountOptions: fbAdAccountOptions
    };
    const finalValue = channelModel.toChannelNativeData(saveValue, extraData);
    callAPIs([
      l1ObjectManager.createL1Object.bind(l1ObjectManager, finalValue)
    ], l1ObjectId => {
      setRedirectData({
        pathname: `${orderDetailPath}/campaign-groups/${l1ObjectId}`,
        state: {
          refreshOrder: shouldRefreshOrder(initL1Object, finalValue)
        }
      });
    });
  };

  const validate = (value: Partial<L1Object>) => {
    const minBudget = channelModel.minBudget;
    const basicError = basicValidate(order, minBudget, _.defaultTo(_.get(order, 'budgetBalance'), 0), value);
    const channelDataError = channelModel.validate(value);
    return _.omitBy({
      ...basicError,
      ...channelDataError
    }, _.isEmpty);
  };

  const showRemainBudget = (values: Partial<L1Object>) => {
    return channelModel.showRemainBudget(values);
  };

  return {
    channelModel,
    formType: 'create',
    title: i18n.t<string>('l1Object.labels.createTitle'),
    redirectData,
    loading,
    initL1Object: initL1Object,
    currentUrl,
    l1ObjectTypeOptions: l1ObjectTypeOptions,
    objectiveOptions,
    cancelTargetPath: orderDetailPath,
    order,
    l2ObjectList: [],
    fbAdAccountOptions,
    canEditBidStrategy: true,
    totalBudget: _.defaultTo(_.get(order, 'budgetBalance'), 0),
    budgetManagementModalData: undefined,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/new',
        breadcrumb: i18n.t<string>('l1Object.labels.createTitle')
      }
    ],
    showManagementModal: () => {
      // This is intentional
    },
    closeManagementModal: () => {
      // This is intentional
    },
    validate,
    save,
    getCboChangeHint: channelModel.getCboChangeHint,
    getDefaultBidStrategy: channelModel.getDefaultBidStrategy,
    onChannelChange,
    onCBOChange: (values: Partial<L1Object>, valueUpdater: (updateFunc: (prevValue: any) => any) => void, enable: boolean) => {
      channelModel.onCBOChange(enable, valueUpdater);
    },
    showRemainBudget
  };
};

const convertArrayToType = <T>(array: any[] | undefined): T[] | undefined => {
  if (!array) {
    return undefined;
  }

  return array as T[];
};

export const useEditL1ObjectFormModel = (
  order: Order,
  l1ObjectId: number,
  addonFeatureManager: AddonFeatureManager,
  initL2ObjectList?: FbAdSet[] | RtbCampaignListBasic[],
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager,
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  advertiserManager: AdvertiserManager = defaultAdvertiserManager
): L1ObjectFormData => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectData, setRedirectData] = useState<{
    pathname: string,
    state?: any
  } | undefined>(undefined);
  const [initL1Object, setInitL1Object] = useState<L1Object | undefined>(undefined);
  const [l2ObjectList, setL2ObjectList] = useState<FbAdSet[] | RtbCampaignListBasic[]>(_.defaultTo(initL2ObjectList, []));
  const match = useRouteMatch();
  const currentUrl = match.url;
  const orderDetailPath = getRedirectPath(currentUrl);
  const location = useLocation();
  const cancelTargetPath = _.get(location.state, 'fromDetail', false) ?
    `${orderDetailPath}/campaign-groups/${l1ObjectId}` :
    orderDetailPath;

  const [budgetManagementModalData, setBudgetManagementModalData] = useState<any>();
  const [fbAdAccountOptions, setFbAdAccountOptions] = useState<SelectOptions[]>([]);
  const [channelModel, setChannelModel] = useState<L1ObjectEditFormChannelModel | undefined>();

  const getL1ObjectFormModel = useCallback((order, l1Object, initL2ObjectList) => {
    const functionMap = {
      [L1ObjectChannel.RTB]: () => new RTBCampaignGroupEditFormModel(order, l1Object, convertArrayToType<RtbCampaignListBasic>(initL2ObjectList)),
      [L1ObjectChannel.FB]: () => new FBCampaignEditFormModel(order, l1Object, convertArrayToType<FbAdSet>(initL2ObjectList)),
      [L1ObjectChannel.RETAIL_MEDIA]: () => new RTBCampaignGroupEditFormModel(order, l1Object, convertArrayToType<RtbCampaignListBasic>(initL2ObjectList)),
      [L1ObjectChannel.EDIMAX]: () => new EdiMaxCampaignGroupEditFormModel(order, l1Object, convertArrayToType<RtbCampaignListBasic>(initL2ObjectList)),
      [L1ObjectChannel.PIC]: () => new PICCampaignGroupEditFormModel(order, l1Object, convertArrayToType<RtbCampaignListBasic>(initL2ObjectList)),
      [L1ObjectChannel.MESSAGE]: () => new MessageCampaignGroupEditFormModel(order, l1Object, convertArrayToType<MessageCampaign>(initL2ObjectList)),
      [L1ObjectChannel.HAMI_VIDEO]: () => new HamiVideoCampaignGroupEditFormModel(order, l1Object, convertArrayToType<RtbCampaignListBasic>(initL2ObjectList))
    };
    const channelModelGetter = _.defaultTo(functionMap[l1Object.channel], () => undefined);
    return channelModelGetter();
  }, []);

  const l1ObjectTypeOptions = useMemo(() => {
    return defaultL1ObjectManager.getL1ObjectChannelOptions()
      .filter((option: SelectOptions) => {
        return addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL[option.value]);
      });
  }, [addonFeatureManager]);
  const objectiveOptions = useMemo(() => {
    const channel: L1ObjectChannel = _.get(initL1Object, 'channel', L1ObjectChannel.RTB);
    return defaultL1ObjectManager.getL1ObjectObjectiveOptions(channel);
  }, [initL1Object]);

  useEffect(() => {
    callAPIs([
      l1ObjectManager.getL1Object.bind(l1ObjectManager, l1ObjectId)
    ], l1Object => {
      const channelModel = getL1ObjectFormModel(order, l1Object, initL2ObjectList);
      setChannelModel(channelModel);
      setInitL1Object({
        ...l1Object,
        budget: _.defaultTo(l1Object.budget, 0)
      });
      if (
        l1Object.adsOrderId !== order.id ||
        !l1ObjectManager.isChannelAllowed(l1Object.channel, addonFeatureManager)
      ) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/edit/error404`;
        setRedirectData({
          pathname: redirectPath
        });
        return; // to prevent the latter API calls and leads to memory leak when L1ObjectForm unmounted
      } else if (l1Object.objective === L1ObjectObjective.UNSPECIFIED || l1Object.isSelfServe) {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/edit/error403`;
        setRedirectData({
          pathname: redirectPath
        });
        return;
      }
      callAPIs([
        async () => channelModel.init((data) => {
          data.fbAdAccountOptions && setFbAdAccountOptions(data.fbAdAccountOptions);
          data.l2ObjectList && setL2ObjectList(data.l2ObjectList);
        })
      ]);
    });
  }, [l1ObjectId, l1ObjectManager, fbAdSetManager, initL2ObjectList, order, advertiserManager, getL1ObjectFormModel, addonFeatureManager, callAPIs]);

  let channelModelActions: ChannelRelatedData = {
    totalBudget: 0,
    showManagementModal: _.noop,
    showRemainBudget: () => false,
    validate: _.noop,
    onCBOChange: _.noop,
    getCboChangeHint: () => undefined,
    getDefaultBidStrategy: () => BidStrategy.LOWEST_COST_WITHOUT_CAP,
    save: () => Promise.resolve()
  };

  if (channelModel) {
    const showManagementModal = (
      values: Partial<L1Object>,
      valuesUpdater: (updateFunc: (prevValue: any) => any) => void
    ) => {
      const modalData = channelModel.getBudgetManagementModalData(values, valuesUpdater);
      setBudgetManagementModalData({
        ..._.omit(modalData, 'onSubmit', 'onCancel'),
        submit: (budgetMap) => {
          modalData.onSubmit(budgetMap);
          setBudgetManagementModalData(undefined);
        },
        cancel: () => {
          modalData.onCancel();
          setBudgetManagementModalData(undefined);
        }
      });
    };

    const showRemainBudget = (values: Partial<L1Object>) => {
      return channelModel.showRemainBudget(values);
    };

    const validate = (values: Partial<L1Object>) => {
      const basicError = basicValidate(order, channelModel.minBudget(values), channelModel.totalBudget, values);
      const channelDataError = channelModel.validate(values);
      return _.omitBy({
        ...basicError,
        ...channelDataError
      }, _.isEmpty);
    };

    const onCBOChange = (values: Partial<L1Object>, valueUpdater: (updateFunc: (prevValue: any) => any) => void, enable: boolean) => {
      channelModel.onCBOChange(enable, valueUpdater, _.partial(showManagementModal, values, valueUpdater));
    };

    const getDefaultBidStrategy = () => {
      if (!initL1Object) {
        return BidStrategy.LOWEST_COST_WITHOUT_CAP;
      }

      return channelModel.getDefaultBidStrategy();
    };

    const save = async (saveValue) => {
      const extraData = {
        order,
        fbAdAccountOptions: fbAdAccountOptions
      };
      const finalValue = channelModel.toChannelNativeData(saveValue, extraData);
      callAPIs([
        l1ObjectManager.updateL1Object.bind(l1ObjectManager, finalValue.l1ObjectId, finalValue)
      ], () => {
        setRedirectData({
          pathname: `${orderDetailPath}/campaign-groups/${finalValue.l1ObjectId}`,
          state: {
            refreshOrder: shouldRefreshOrder(initL1Object, finalValue)
          }
        });
      });
    };

    channelModelActions = {
      totalBudget: channelModel.totalBudget,
      showManagementModal,
      showRemainBudget,
      validate,
      onCBOChange,
      getCboChangeHint: channelModel.getCboChangeHint,
      getDefaultBidStrategy,
      save
    };
  }

  return {
    channelModel,
    formType: 'edit',
    title: i18n.t<string>('l1Object.labels.editTitle'),
    redirectData,
    loading,
    initL1Object: initL1Object,
    l1ObjectTypeOptions: l1ObjectTypeOptions,
    objectiveOptions,
    currentUrl,
    cancelTargetPath,
    order,
    l2ObjectList,
    fbAdAccountOptions,
    canEditBidStrategy: false,
    budgetManagementModalData,
    breadcrumbs: [
      { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
      {
        path: '/orders/:orderNumber',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(order, 'projectName'),
          matchParam: 'orderNumber'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: _.get(initL1Object, 'name'),
          matchParam: 'l1ObjectId'
        }
      },
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(initL1Object, 'name'),
          matchParam: 'l1ObjectId'
        }
      }
    ],
    closeManagementModal: () => setBudgetManagementModalData(undefined),
    onChannelChange: () => {
      // This is intentional
    },
    ...channelModelActions
  };
};

function shouldRefreshOrder (initL1Object, finalL1Object) {
  let initBudget = _.get(initL1Object, 'budget', null);
  initBudget = initBudget === '' ? null : initBudget;
  return initBudget !== finalL1Object.l1ObjectLifetimeBudget ||
    _.get(initL1Object, 'autoOptimise', false) !== finalL1Object.autoOptimise;
}

const basicValidate = (order: Order, minBudget: number, totalBudget: number, value: Partial<L1Object>) => {
  const validateBudget = (budget) => {
    let error = validateEmpty(budget);
    if (error) {
      return error;
    }
    return validateMinMax(budget, getPriceValue(order.currency, minBudget), totalBudget);
  };
  return _.omitBy({
    name: validateEmpty(value.name),
    budget: validateBudget(value.budget)
  }, _.isEmpty);
};

const getRedirectPath = (currentUrl: string) => {
  const regMatch = currentUrl.match(/\/orders\/\d+\//);
  return regMatch ? regMatch[0].slice(0, -1) : '/';
};
