import { renderColumn } from 'components/TableColumn/TableColumn';
import { ComboCreativeListDataProvider } from './ComboCreativeListDataProvider';
import { commonColumnSetting, CreativeListCommonColumns } from './CreativeListCommonColumnData';

export class HamiVideoComboCreativeListDataProvider extends ComboCreativeListDataProvider {

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.STATUS], this.formatters.stateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LANDING_URL], this.formatters.urlFormatter),
      // renderColumn(commonColumnSetting[CreativeListCommonColumns.BIND_STATE], this.formatters.bindStateFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }
}
