import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import i18n from 'i18next';
import { CreativeSetupTab } from './CreativeSetupStepModel';
import _ from 'lodash';
import { SummaryData, SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import { toServerStructure } from 'utils/LimitationUtil';
import { CreativeFormData } from './SubSteps/FormContent/FormContentModel';
import { CreativeSummaryModel } from './SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from '../CreativeSetupFlowDataContext';
import { isImage } from 'core/creative/Creative';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AdLogoType } from 'core/adLogo/AdLogo';
import defaultAdLogo from 'assets/campaign/tenmax-ad-logo.svg';
import { FlowMediaSummarySectionProps, FlowSummarySectionProps, FlowSummarySectionTitleProps } from 'components/FlowSummarySection/FlowSummarySection';
import { ImagePreviewProps } from 'components/common/Image/ImagePreview';

export enum CreateCreativeSetupFlowStep {
  CHOOSE_ADVERTISER,
  SETUP_CREATIVE,
  SUMMARY
}

export enum EditCreativeSetupFlowStep {
  SETUP_CREATIVE,
  SUMMARY
}

export type CreativeSummaryStepState = {
  readonly loading: boolean;
  readonly basicSummaryData?: FlowSummarySectionProps;
  readonly submitAlertModalData: any;
  readonly mediaSummaryData?: FlowMediaSummarySectionProps;
};

export type AdLogoPreviewData = FlowSummarySectionTitleProps & { summaryData: ImagePreviewProps };

export interface CreativeSummaryStepModel {
  readonly state: CreativeSummaryStepState;
  readonly event: UpdateEventListener<CreativeSummaryStepModel>;
  readonly mediaSummaryData?: FlowMediaSummarySectionProps;
  getAdvertiserSummaryData: (advertisers: Array<SelectOptions>, advertiserId: number) => FlowSummarySectionProps;
  getLimitationsSummaryData: () => FlowSummarySectionProps | undefined;
  getAdLogoSummaryData: () => FlowSummarySectionProps | undefined;
  getAdLogoPreviewData: () => AdLogoPreviewData | undefined;
  getJsonToSubmit (): Promise<Object>;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  submit: (event, callback?: () => void) => void;
  initContextData (contextData: CreativeSetupFlowDataContextType);
  initSummaryData (): Promise<void>;
  setBasicSummaryData (basicSummaryData);
  finishedCallback?: (redirectData: {
    pathname: string,
    search?: string,
    state?: any
  }) => void;
}

export type CreativeSummaryStepProps = {
  readonly model: CreativeSummaryStepModel;
};

abstract class DefaultCreativeSummaryStepModel implements CreativeSummaryStepModel {
  event: FireableUpdateEventListener<CreativeSummaryStepModel>;
  loading: boolean;
  finishedCallback?: (redirectData: {
    pathname: string,
    search?: string,
    state?: any
  }) => void;
  creativeFormData?: CreativeFormData;
  summaryContentModel?: CreativeSummaryModel;
  basicSummaryData?: FlowSummarySectionProps;
  mediaSummaryData?: FlowMediaSummarySectionProps;
  submitAlertModalData?: any;

  constructor (
    protected type: string,
    private canChooseAdvertiser: boolean,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex?: number) => void,
    protected getUploadedFileData: (file: File) => Promise<any>,
    protected addUploadedFilesData: (file: File, data: any) => Promise<void>,
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected campaignId?: string,
    protected orderNumber?: string,
    protected l1ObjectId?: string | null,
    protected draftId?: string | null
  ) {
    this.event = new FireableUpdateEventListener<CreativeSummaryStepModel>();
    this.loading = false;
  }

  get state (): CreativeSummaryStepState {
    return {
      loading: this.loading,
      basicSummaryData: this.basicSummaryData,
      submitAlertModalData: this.submitAlertModalData,
      mediaSummaryData: this.mediaSummaryData
    };
  }

  get creativeSetupStepIndex () {
    if (this.type === 'create' && this.canChooseAdvertiser) {
      return CreateCreativeSetupFlowStep.SETUP_CREATIVE;
    } else {
      return EditCreativeSetupFlowStep.SETUP_CREATIVE;
    }
  }

  get creativeManagementSearchParams () {
    const campaignParam = this.draftId ? `draftIds=${this.draftId}` : `campaignIds=${this.campaignId}`;
    return `?${campaignParam}&action=manage`;
  }

  initContextData (contextData: CreativeSetupFlowDataContextType) {
    if (contextData.creative !== this.creativeFormData) {
      this.summaryContentModel = contextData.getSummaryModel(contextData.creative.basic);
      this.creativeFormData = contextData.creative;
      this.finishedCallback = contextData.setFinishedRedirectData;
    }
  }

  async initSummaryData () {
    if (this.summaryContentModel) {
      this.updateState(true);
      try {
        const summaryData = await this.summaryContentModel.getCreativeBasicSummaryData(this.getUploadedFileData, this.addUploadedFilesData);
        this.basicSummaryData = {
          title: i18n.t<string>('stepSideBar.labels.creativeBasic'),
          summaryData: {
            general: {
              title: i18n.t<string>('summary.titles.generalInfo'),
              content: summaryData
            }
          },
          goStep: () => this.goStep(this.creativeSetupStepIndex, CreativeSetupTab.BASIC)
        };
        this.mediaSummaryData = this.getCreativeMediaSummaryData();
      } catch (e) {
        console.error(e);
      }
      this.updateState(false);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  getAdvertiserSummaryData (advertisers, advertiserId) {
    const advertiser = _.find(advertisers, advertiser => advertiser.value === advertiserId);
    return {
      title: i18n.t<string>('stepSideBar.labels.creativeChooseAdvertiser'),
      summaryData: {
        advertiser: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('creativeSetupFlow.labels.advertiser'),
              value: advertiser ? advertiser.label : ''
            }
          ]
        }
      }
    };
  }

  // getBasicSetupSummaryData () {
  //   const basicSetupSummary = this.summaryContentModel ? this.summaryContentModel.getCreativeBasicSummaryData() : {};
  //   return {
  //     title: i18n.t<string>('stepSideBar.labels.creativeBasic'),
  //     backStep: this.creativeSetupStepIndex,
  //     backSubStep: CreativeSetupTab.BASIC,
  //     data: {
  //       general: {
  //         title: i18n.t<string>('summary.titles.generalInfo'),
  //         content: basicSetupSummary
  //       }
  //     }
  //   };
  // }

  getMediaSummaryComponent () {
    return this.summaryContentModel ? this.summaryContentModel.getMediaSummaryComponent() : undefined;
  }

  getLimitationsSummaryData (): FlowSummarySectionProps | undefined {
    if (!this.creativeFormData) {
      return;
    }
    const limitations = this.creativeFormData.limitations;
    if (!limitations) {
      return;
    }

    const summaryData = this.creativeManager.getLimitationSummaryData(limitations);

    return {
      title: i18n.t<string>('stepSideBar.labels.creativeLimitation'),
      summaryData: {
        include: {
          title: i18n.t<string>('campaignSummary.titles.inc'),
          titlePrefixColor: SummaryTitleColor.GREEN,
          content: summaryData.include
        },
        preferred: {
          title: i18n.t<string>('campaignSummary.titles.preferred'),
          titlePrefixColor: SummaryTitleColor.BLUE,
          content: summaryData.preferred
        },
        nonPreferred: {
          title: i18n.t<string>('campaignSummary.titles.nonPreferred'),
          titlePrefixColor: SummaryTitleColor.YELLOW,
          content: summaryData.nonPreferred
        },
        exclude: {
          title: i18n.t<string>('campaignSummary.titles.exc'),
          titlePrefixColor: SummaryTitleColor.RED,
          content: summaryData.exclude
        },
        other: {
          title: i18n.t<string>('campaignSummary.titles.other'),
          content: summaryData.other
        }
      },
      goStep: () => this.goStep(this.creativeSetupStepIndex, CreativeSetupTab.LIMITATION)
    };
  }

  getFileSummaryData (image) {
    const file = _.get(image, 'file');
    const url = _.get(image, 'url');
    if (file || url) {
      return {
        file,
        url
      };
    }
  }

  getAdLogoSummaryData (): FlowSummarySectionProps | undefined {
    if (!this.creativeFormData) {
      return;
    }
    const adLogo = this.creativeFormData.basic.adLogo;
    if (!adLogo) {
      return;
    }
    return {
      title: i18n.t<string>('campaignSummary.titles.adLogo'),
      summaryData: {
        adLogoBasic: {
          content: _.compact([
            {
              label: i18n.t<string>('adLogoForm.labels.logoType'),
              value: i18n.t<string>(`adLogoForm.labels.${adLogo.type.toLowerCase()}`)
            },
            adLogo.type === AdLogoType.CUSTOM ? {
              label: i18n.t<string>('adLogoForm.labels.logoLink'),
              value: adLogo.link
            } : undefined
          ]) as SummaryData[]
        }
      },
      goStep: () => this.goStep(this.creativeSetupStepIndex, CreativeSetupTab.ADLOGO)
    };
  }

  getAdLogoPreviewData (): AdLogoPreviewData | undefined {
    if (!this.creativeFormData) {
      return;
    }
    const adLogo = this.creativeFormData.basic.adLogo;
    if (!adLogo) {
      return;
    }
    if (adLogo.type !== AdLogoType.NULL) {
      let imageData = {
        url: adLogo.image?.url,
        file: adLogo.image?.file
      };
      const config = {
        showName: true,
        showSize: true
      };
      if (adLogo.type === AdLogoType.DEFAULT) {
        imageData = { url: defaultAdLogo, file: undefined };
        config.showName = false;
      }
      return {
        title: i18n.t<string>('adLogoForm.labels.logoPreview'),
        summaryData: {
          ...imageData,
          config
        },
        goStep: () => this.goStep(this.creativeSetupStepIndex, CreativeSetupTab.ADLOGO)
      };
    }
  }

  getCreativeMediaSummaryData (): FlowMediaSummarySectionProps | undefined {
    const mediaData = this.summaryContentModel ? this.summaryContentModel.getMediaSummary() : undefined;
    if (_.isEmpty(mediaData)) {
      return undefined;
    }
    return {
      title: i18n.t<string>('creativeSetupFlow.labels.creativeBinding'),
      summaryData: mediaData,
      MediaComponent: this.getMediaSummaryComponent(),
      goStep: () => this.goStep(this.creativeSetupStepIndex, CreativeSetupTab.BASIC)
    };
  }

  async getFormDataToSubmit () {
    if (!this.summaryContentModel || !this.creativeFormData) {
      return;
    }

    if (isImage(this.creativeFormData.basic.creativeType) && this.type === 'create') {
      const jsonData = await this.summaryContentModel.getJsonSubmitData();
      const limitations = toServerStructure(this.creativeFormData.limitations);
      if (Array.isArray(jsonData)) {
        jsonData.forEach(data => {
          data.limitations = limitations;
        });
      } else {
        jsonData.limitations = limitations;
      }
      return jsonData;
    }

    const formData = await this.summaryContentModel.getBasicSubmitData();
    if (!this.creativeFormData.limitations || _.isEmpty(this.creativeFormData.limitations)) {
      return formData;
    }
    const limitations = toServerStructure(this.creativeFormData.limitations);
    limitations.forEach((limitation, index) => {
      formData.append(`limitations[${index}].op`, limitation.op);
      formData.append(`limitations[${index}].isGroup`, limitation.isGroup.toString());
      formData.append(`limitations[${index}].type`, limitation.type);
      limitation.limits.forEach((limit, limitIndex) => {
        formData.append(`limitations[${index}].limits[${limitIndex}].label`, limit.label);
        formData.append(`limitations[${index}].limits[${limitIndex}].value`, limit.value);
      });
    });
    return formData;
  }

  async getJsonToSubmit () {
    if (!this.summaryContentModel) {
      throw new Error('Summary content model is not initialized');
    }
    return this.summaryContentModel.getJsonSubmitData();
  }

  setBasicSummaryData (basicSummaryData) {
    this.basicSummaryData = basicSummaryData;
    this.updateState(false);
  }

  abstract submitMethod (submitData): Promise<void>;

  checkSubmitAlert = () => {
    if (!this.summaryContentModel || !this.creativeFormData) {
      return;
    }
    const alertMessage = this.summaryContentModel.getSubmitAlertMessage();
    if (alertMessage) {
      this.submitAlertModalData = {
        title: i18n.t<string>('common.warning'),
        message: alertMessage,
        submit: async () => {
          this.updateState(true);
          const submitData = await this.getFormDataToSubmit();
          if (!submitData) {
            this.updateState(false);
            return;
          }
          await this.submitMethod(submitData);
          this.updateState(false);
        },
        cancel: async () => {
          this.submitAlertModalData = undefined;
          this.updateState(false);
        }
      };
      this.updateState(false);
    }
    return alertMessage !== undefined;
  }

  submit = async (evt, callback) => {
    if (this.checkSubmitAlert()) {
      return;
    }
    this.updateState(true);
    _.defer(async () => {
      try {
        const submitData = await this.getFormDataToSubmit();
        if (!submitData) {
          this.updateState(false);
          return;
        }

        await this.submitMethod(submitData);
        this.updateState(false);
      } catch (e) {
        (e instanceof Error) && toast.error(e.message);
        this.updateState(false);
      }
      callback && callback();
    });
  }
}

export class CreateCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    if (this.creativeFormData && isImage(this.creativeFormData.basic.creativeType)) {
      await this.createCreativeByJson(submitData);
    } else {
      await this.createCreative(submitData);
    }
  }

  createCreative = async (creative: FormData) => {
    await this.creativeManager.createCreative(creative);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives'
    });
  }

  createCreativeByJson = async (creativeJson: any) => {
    const data = await this.creativeManager.createCreativesByJson(creativeJson);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives',
      state: {
        errors: _.get(data, 'error')
      }
    });
  }
}

export class EditCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    await this.creativeManager.updateCreative(submitData);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.updateSuccess'));
    if (_.isNil(this.orderNumber) || (_.isNil(this.campaignId) && _.isNil(this.draftId))) {
      this.finishedCallback && this.finishedCallback({
        pathname: '/creatives'
      });
    } else {
      this.finishedCallback && this.finishedCallback({
        pathname: `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}`,
        search: this.creativeManagementSearchParams
      });
    }
  }
}

abstract class CreateCreativeAndBindSummaryStepModel extends DefaultCreativeSummaryStepModel {

  abstract get channel ();

  async submitMethod (submitData) {
    const isDraft = !!this.draftId;
    if (
      this.creativeFormData &&
      isImage(this.creativeFormData.basic.creativeType)
    ) {
      await this.creativeManager.createCreativesByJsonAndBind(
        this.channel,
        submitData,
        isDraft ? this.draftId! : this.campaignId!,
        isDraft
      );
    } else {
      await this.creativeManager.createCreativeAndBind(
        this.channel,
        submitData,
        isDraft ? this.draftId! : this.campaignId!,
        isDraft
      );
    }
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}`,
      search: this.creativeManagementSearchParams
    });
  }
}

export class CreateCreativeAndBindRTBCampaignSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return L1ObjectChannel.RTB;
  }
}

export class CreateCreativeAndBindAdSetSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return L1ObjectChannel.FB;
  }
}
