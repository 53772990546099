import React from 'react';
import { connect, FormikContextType } from 'formik';
import i18n from 'i18n';
import _ from 'lodash';

import { RtbCampaignBasic, ViewTrackingTrigger, ViewTrackingType } from 'core/rtbCampaign/RtbCampaign';

import styles from './rtbCampaignTrackForm.module.scss';
import { FormikField } from 'components/common/form/field/FormikField';
import { CustomField } from 'components/common/form/field/CustomField';
import { ThirdPartyTrackingCodes } from 'components/ThirdPartyTrackingCode/ThirdPartyTrackingCodes';

type CampaignTrackFormProps = {
  trackingConfig: {
    conversionTracking: {
      options?: SelectOptions[];
      conversionTrackingTypeOptions?: SelectOptions[];
    }
    canUseVideoProgressTracking: boolean;
    getThirdPartyTrackingTypeOptions: () => {label: string, value: ViewTrackingType}[];
    getThirdPartyTriggerTypeOptions: (trackingType: ViewTrackingType) => {label: string, value: ViewTrackingTrigger}[];
  }
};

const CampaignTrackForm: React.FC<CampaignTrackFormProps & { formik: FormikContextType<RtbCampaignBasic> }
> = ({
  formik,
  trackingConfig
}) => {
  const formikValue = formik.values;
  const {
    conversionTracking: conversionTrackingConfig,
    canUseVideoProgressTracking,
    getThirdPartyTrackingTypeOptions,
    getThirdPartyTriggerTypeOptions
  } = trackingConfig;

  const conversionTracking = _.get(formikValue, 'conversionTracking');
  return (
    <div>
      {conversionTrackingConfig.options && (
        <fieldset>
          <legend>
            <span>
              {i18n.t<string>(
                'campaign.strategyAndTracking.conversionTracking'
              )}
            </span>
          </legend>
          <div className={styles.component}>
            <FormikField.Select
              name='conversionTracking'
              label={i18n.t<string>(
                'campaign.strategyAndTracking.conversionTracking'
              )}
              options={conversionTrackingConfig.options}
              isClearable={true}
            />
            {!_.isNil(conversionTracking) &&
              conversionTrackingConfig.conversionTrackingTypeOptions &&
              conversionTrackingConfig.conversionTrackingTypeOptions.length > 0 && (
                <FormikField.ToggleButton
                  label={i18n.t<string>('campaign.labels.convTrackEvent')}
                  name='convTrackEvent'
                  options={conversionTrackingConfig.conversionTrackingTypeOptions}
                />
              )}
          </div>
        </fieldset>
      )}
      <fieldset>
        <legend>
          <span>
            {i18n.t<string>('campaign.strategyAndTracking.thirdPartyTracking')}
          </span>
        </legend>
        <div className={styles.component}>
          <CustomField
            name='viewTrackingCodesContainer'
            label={i18n.t<string>(
              'campaign.strategyAndTracking.thirdPartyTracking'
            )}
            hint={
              <span className={styles.hint}>
                {i18n.t<string>('campaign.strategyAndTracking.asiaMaxOnlyHint')}
              </span>
            }
          >
            <ThirdPartyTrackingCodes
              getTrackingTypeOptions={getThirdPartyTrackingTypeOptions}
              getTriggerTypeOptions={getThirdPartyTriggerTypeOptions}
            />
          </CustomField>
        </div>
      </fieldset>
      {canUseVideoProgressTracking && (
        <fieldset>
          <legend>
            <span>
              {i18n.t<string>(
                'campaign.strategyAndTracking.videoProgressTracking'
              )}
            </span>
          </legend>
          <div className={styles.component}>
            <FormikField.Input
              name='videoProgressTrackingCode.code'
              label={i18n.t<string>(
                'campaign.strategyAndTracking.videoProgressTrackingCode'
              )}
              as='textarea'
              rows={4}
              hint={
                <span className={styles.hint}>
                  {i18n.t<string>(
                    'campaign.strategyAndTracking.asiaMaxOnlyHint'
                  )}
                </span>
              }
            />
            <FormikField.Input
              name='videoProgressTrackingCode.offset'
              label={i18n.t<string>(
                'campaign.strategyAndTracking.videoProgressTrackingCodeOffset'
              )}
              type='number'
              min={0}
            />
          </div>
        </fieldset>
      )}
    </div>
  );
};

export default connect<CampaignTrackFormProps, RtbCampaignBasic>(CampaignTrackForm);
