import { useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { ChannelGroupForm } from './ChannelGroupForm/ChannelGroupForm';
import { ChannelGroupList } from './ChannelGroupList/ChannelGroupList';
import { ChannelGroupDetail } from './ChannelGroupDetail/ChannelGroupDetail';
import { useCallAPI } from 'hooks/useCallAPI';
import { ChannelGroupManager, DefaultChannelGroupManager } from 'core/channelGroup/ChannelGroupManager';
import { ChannelGroupListDTO } from 'core/channelGroup/ChannelGroup';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

const channelGroupManager: ChannelGroupManager = new DefaultChannelGroupManager();

export const ChannelGroups: React.FC = () => {

  const { loading, callAPIs } = useCallAPI();
  const [channelGroups, setChannelGroups] = useState<ChannelGroupListDTO[]>([]);

  useEffect(() => {
    callAPIs([() => channelGroupManager.getChannelGroups()], setChannelGroups);
  }, [callAPIs]);

  const renderEditForm = useCallback(() => {
    return <ChannelGroupForm channelGroups={channelGroups}/>;
  }, [channelGroups]);

  const renderDetail = useCallback(() => {
    return <ChannelGroupDetail />;
  }, []);

  const renderList = useCallback(() => {
    return <ChannelGroupList channelGroups={channelGroups}/>;
  }, [channelGroups]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <Switch>
        <Route path={'/system/channel-groups/:id/edit'} render={renderEditForm} />
        <Route path={'/system/channel-groups/:id'} render={renderDetail} />
        <Route render={renderList} />
      </Switch>
    </>
  );
};
