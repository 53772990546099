import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { L1ObjectListColumns, L1ObjectListType, useL1ObjectListModel } from './L1ObjectListModel';
import i18n from 'i18n';
import styles from './l1ObjectList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import { Nav } from 'react-bootstrap';
import { AddonFeatureManager } from 'core';
import { Order } from 'core/order/Order';
import cx from 'classnames/bind';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs, isPmax3Order } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import Select from 'components/common/Select/Select';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { FilterMenuTab } from 'components/common/FilterMenuTab/FilterMenuTab';
import { AuditLog } from 'containers/AuditLog/AuditLog';
const classNames = cx.bind(styles);

export type L1ObjectListProps = {
  order: Order,
  l1ObjectList: any[],
  refreshList: () => void,
  addonFeatureManager: AddonFeatureManager
};

export const L1ObjectList: React.FC<L1ObjectListProps> = ({
  order,
  l1ObjectList,
  refreshList,
  addonFeatureManager
}) => {

  const {
    state,
    columns,
    loading,
    channels,
    currentUrl,
    auditLogModel,
    metricsGroupOptions,
    canNotCreateMessage,
    filterMenuTabConfigs,
    selectedL1Objects,
    actionable,
    onHandleSearch,
    onMetricsGroupChange,
    onListChannelChange,
    onDeleteModalClose,
    handleRemoveSelect,
    activeL1Object,
    deactiveL1Object
  } = useL1ObjectListModel(order, l1ObjectList, refreshList, addonFeatureManager);

  const showAuditLog = state.selectedMetricsGroup === L1ObjectListType.AUDIT_LOG;

  const renderEditBar = () => {
    return (
      <ListEditBar selectedItems={selectedL1Objects} handleRemoveSelect={handleRemoveSelect}>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={activeL1Object} disabled={!actionable}>
            {i18n.t<string>('campaignList.labels.activeCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item >
          <ListEditBar.Link onClick={deactiveL1Object} disabled={!actionable}>
            {i18n.t<string>('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
      </ListEditBar>
    );
  };

  const createContainerClassName = classNames('createL1Object', 'tipContainer', {
    block: canNotCreateMessage !== ''
  });

  const renderAuditLog = () => {
    if (!auditLogModel) {
      return <div />;
    }
    return (<AuditLog model={auditLogModel} />);
  };

  return (
    <div className={styles.l1ObjectList}>
      {loading && <LoadingIndicator />}
      {state.deleteL1Object &&
        <Modal
          title={i18n.t<string>('l1Object.labels.deleteTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: onDeleteModalClose }}
          primaryButton={{ title: i18n.t<string>('common.buttons.delete'), callback: state.deleteL1Object }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t<string>('l1Object.labels.deleteModalContent')}
        </Modal>
      }
      <div className={styles.titleBar}>
        {selectedL1Objects.length > 0 ?
          renderEditBar() :
          <div className={styles.filterArea}>
            <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE).and(isPmax3Order(order.id))}>
              <div className={createContainerClassName}>
                {canNotCreateMessage &&
                  <span className={styles.tooltip}>
                    {canNotCreateMessage}
                  </span>
                }
                <Link
                  to={`${currentUrl}/campaign-groups/new`}
                  className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
                >
                  {i18n.t<string>('l1Object.labels.create')}
                </Link>
              </div>
            </PermissionChecker>
            {
              !showAuditLog &&
                <>
                  <FilterMenuTab
                    filterMenuTabConfigs={filterMenuTabConfigs}
                  />
                  <SearchBar
                    model={{
                      placeholder: i18n.t<string>('l1Object.placeholders.searchbar'),
                      search: onHandleSearch,
                      defaultValue: state.searchString
                    }}
                  />
                </>
            }

          </div>
        }
      </div>
      <div className={styles.contentArea}>
        <div className={styles.listConditionArea}>
          <Nav
            activeKey={state.selectedChannel}
            onSelect={onListChannelChange}
          >
            {
              channels.map(channel => (
                <Nav.Item key={channel}>
                  <Nav.Link eventKey={channel}>
                    {i18n.t<string>(`l1Object.labels.channel_${channel.toLowerCase()}`)}
                    {l1ObjectList.filter(l1Object => l1Object.drafts > 0 && l1Object.channel === channel).length > 0 &&
                      <div className={styles.draftHint}/>
                    }
                  </Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
          <div className={styles.metricsOptionsContainer}>
            <Select
              className={styles.metricsOptions}
              options={metricsGroupOptions}
              name='metricsGroupOptions'
              simpleValue
              value={state.selectedMetricsGroup}
              onChange={onMetricsGroupChange}
            />
          </div>
        </div>
        {showAuditLog ?
          renderAuditLog() :
          <StickableBootstrapTable
            key={state.selectedMetricsGroup}
            stickFirstColumn
            stickLastColumn
            keyField={L1ObjectListColumns.ID}
            data={state.filteredList}
            columns={columns}
            summaryData={state.summaryData}
            hidePagination={true}
            noDataIndication={i18n.t<string>('l1Object.labels.noData')}
            defaultSorted={[{
              dataField: L1ObjectListColumns.ID,
              order: 'desc'
            }]}
          />
        }
      </div>
    </div>
  );
};
