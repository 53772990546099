type TenMaxLineBeaconConfig = {
  lineBeaconServiceType: LineBeaconServiceType.TENMAX;
  lineBeaconHardwareId: string;
  lineBotChannelSecret: string;
  lineBotChannelAccessToken: string;
};
type SelfServeLineBeaconConfig = OverrideAndNeverOthers<
  TenMaxLineBeaconConfig,
  {
    lineBeaconServiceType: LineBeaconServiceType.SELF_SERVE;
    lineBeaconHardwareId: string;
  }
>;

export type LineBeaconConfig =
  | TenMaxLineBeaconConfig
  | SelfServeLineBeaconConfig;

export type Advertiser = {
  id: number;
  agencyId: number;
  advertiserName: string;
  website: string;
  category?: string;
  note?: string;
  productFeedID: string;
  isTrackingCodeReady: boolean;
  isProductFeedReady: boolean;
  preferredPublisherId: Array<number>;
  shareSegment: ShareSegment[];
  channelAccounts: ChannelAccount[];
} & (LineBeaconConfig | undefined);

export enum LineBeaconServiceType {
  TENMAX = 'tenmaxSupport',
  SELF_SERVE = 'advertiserSelfServe'
}

export type ChannelAccount = {
  accountId: string;
  channelType: string;
} & {
  fbPageIds?: string[];
};

export type ShareSegment = {
  agencyId: number;
  advertiserId: number;
  agencyName: string;
  advertiserName: string;
};
