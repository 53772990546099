import _ from 'lodash';
import client from './RestClient';
import { FinalReportGender, FinalReportTargetType, Order, OrderType } from '../core/order/Order';
import { getNumberAccurateToDecimalPlace } from 'utils/Math';
import { Pagination } from 'core/pagination/Pagination';
import { Pageable } from './Pageable';
import { AdLogoType } from 'core/adLogo/AdLogo';
import { FinalReportStatus } from 'core/finalReport/FinalReportStatus';
import { RTBCAMPAIGN_DEFAULT_AGE_MAX, RTBCAMPAIGN_DEFAULT_AGE_MIN } from 'core/rtbCampaign/RtbCampaign';
import { defaultCampaignBidPrice } from 'containers/Agencies/AgencyForm/defaultCampaignBidPrice';
import { AxiosInstance } from 'axios';

export interface OrderWebService {
  getOrder (orderNumber: string): Promise<Order>;
  getOrderById (orderId: string | number): Promise<Order>;
  getOrders (advertiserId: number | null | undefined,
    pageable: Pageable,
    search?: string,
    deliveries?: string[]): Promise<OrdersWithPagination>;
  approveOrder (orderId: number): Promise<void>;
  rejectOrder (orderId: number): Promise<void>;
  createOrder (order: Order): Promise<Order>;
  updateOrder (order: Order): Promise<Order>;
  settleOrder (orderId: number): Promise<void>;
  unsettleOrder (orderId: number): Promise<void>;
  getOrderOptions (orderType?: OrderType): Promise<Array<SelectOptions>>;
  getExistedProjectNames (): Promise<string[]>;
  getFinalReportStatus (orderId: number): Promise<FinalReportStatus>;
}

export type OrdersWithPagination = {
  pagination: Pagination,
  orders: Array<Order>
};

function wrapCampaignConstraint (json: any): any {
  return {
    bidPriceCPCMin: getNumberAccurateToDecimalPlace(_.get(json, 'bidPriceCPCMin', 0), 2),
    bidPriceCPVMin: getNumberAccurateToDecimalPlace(_.get(json, 'bidPriceCPVMin', 0), 2),
    bidPriceCPMMin: getNumberAccurateToDecimalPlace(_.get(json, 'bidPriceCPMMin', 0), 2),
    bidPricevCPMMin: getNumberAccurateToDecimalPlace(_.get(json, 'bidPricevCPMMin', 0), 2),
    budgetMinimum: _.get(json, 'budgetMinimum'),
    campaignBudgetMinimum: _.get(json, 'campaignBudgetMinimum')
  };
}

function wrapBidPriceDataOfAdTypes (json: any): any {
  if (!json) {
    return [];
  }
  const result: any[] = [];
  const bidPriceSetting = _.merge(defaultCampaignBidPrice, json);
  bidPriceSetting.forEach(bidPriceData => {
    let bidPriceDataResult: any = {
      type: _.get(bidPriceData, 'type')
    };
    bidPriceDataResult.autoBidCap = wrapBidPriceData(bidPriceData.autoBidCap);
    bidPriceDataResult.bidFloor = wrapBidPriceData(bidPriceData.bidFloor);
    result.push(bidPriceDataResult);
  });
  return result;
}

function wrapBidPriceData (json: any): any {
  if (!json) {
    return {};
  }
  let bidFloorResult = {};
  Object.keys(json).forEach((key) => {
    const resultKey = key.toLowerCase();
    bidFloorResult[resultKey] = json[key];
  });
  return bidFloorResult;
}

function wrapOrder (json: any): any {
  const orderType = _.get(json, 'orderType');
  return _.omitBy({
    id: _.get(json, 'orderId'),
    agencyId: _.get(json, 'agencyId'),
    projectName: _.get(json, 'projectName'),
    orderNumber: _.get(json, 'orderNumber'),
    advertiserId: _.get(json, 'advertiserId'),
    budget: _.get(json, 'budget'),
    orderType,
    originBudget: _.get(json, 'originBudget'),
    changeBudget: _.get(json, 'changeBudget'),
    orderMargin: _.get(json, 'orderMargin'),
    changeOrderMargin: _.get(json, 'changeOrderMargin'),
    sysMargin: _.get(json, 'sysMargin'),
    picMargin: _.get(json, 'picMargin'),
    edimaxMargin: _.get(json, 'edimaxMargin'),
    externalId: _.get(json, 'externalId'),
    state: _.get(json, 'state'),
    spent: _.get(json, 'spent'),
    creator: _.get(json, 'creator'),
    creatorEmail: _.get(json, 'creatorEmail'),
    expectedSpent: _.get(json, 'expectedSpent'),
    actualSpent: _.get(json, 'actualSpent'),
    comments: _.get(json, 'comments'),
    startDate: _.get(json, 'startDate'),
    endDate: _.get(json, 'endDate'),
    createDate: _.get(json, 'createDate'),
    createDateTime: _.get(json, 'createDateTime'),
    campaignConstraint: wrapCampaignConstraint(_.get(json, 'campaignConstraint')),
    budgetBalance: _.get(json, 'budgetBalance'),
    currency: _.get(json, 'currency'),
    timezone: _.get(json, 'timezone'),
    impres: _.get(json, 'impres', 0),
    viewable: _.get(json, 'viewable', 0),
    clicks: _.get(json, 'clicks', 0),
    adView: _.get(json, 'adView', 0),
    modifyReason: _.get(json, 'modifyReason'),
    rtbCampaignRunningStatus: _.get(json, 'rtbCampaignRunningStatus'),
    needAlert: _.get(json, 'needAlert'),
    externalType: _.get(json, 'externalType'),
    campaignBidPrice: wrapBidPriceDataOfAdTypes(_.get(json, 'campaignBidPrice')),
    campaignMinStartDate: _.get(json, 'campaignMinStartDate'),
    campaignMaxEndDate: _.get(json, 'campaignMaxEndDate'),
    monitor: _.get(json, 'monitor', false),
    orderPrice: _.get(json, 'orderPrice', 0),
    creativeDuration: _.get(json, 'creativeDuration', 0),
    dayPart: _.get(json, 'dayPart', {}),
    finalReportProjectName: _.get(json, 'finalReportProjectName'),
    finalReportAdvertiserName: _.defaultTo(_.get(json, 'finalReportAdvertiserName'), ''),
    finalReportProjectType: _.get(json, 'finalReportProjectType'),
    finalReportReceivers: _.get(json, 'finalReportReceivers'),
    finalReportSendOutDate: _.get(json, 'finalReportSendOutDate'),
    finalReportTargetType: _.get(json, 'finalReportTargetType', FinalReportTargetType.IMPRESSION),
    finalReportTargetValue: _.defaultTo(_.get(json, 'finalReportTargetValue', _.get(json, 'finalReportTargetImpressions')), ''),
    ageMin: orderType === OrderType.TENMAX ? _.get(json, 'ageMin', RTBCAMPAIGN_DEFAULT_AGE_MIN) : undefined,
    ageMax: orderType === OrderType.TENMAX ? _.get(json, 'ageMax', RTBCAMPAIGN_DEFAULT_AGE_MAX) : undefined,
    gender: orderType === OrderType.TENMAX ? +(_.get(json, 'gender', FinalReportGender.ALL)) : undefined
  }, _.isNull);
}

function wrapOrderDetail (json: any): any {
  const order = wrapOrder(json);
  return {
    ...order,
    adLogo: wrapAdLogo(_.get(json, 'adLogo'))
  };
}

function wrapAdLogo (json: any) {
  return {
    type: _.get(json, 'type', AdLogoType.NULL),
    link: _.get(json, 'link'),
    image: _.get(json, 'imgUrl') ? {
      url: _.get(json, 'imgUrl')
    } : undefined
  };
}

function wrapOrderList (json: any): any {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapOrder(json)
  );
}

function wrapPagination (json: any): any {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

export class RestfulOrderWebService implements OrderWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getOrder (orderNumber: string): Promise<Order> {
    const response = await this.restClient.get(`/v2/adsOrders/orderNumbers/${orderNumber}`);
    return wrapOrderDetail(response.data);
  }

  async getOrderById (orderId: string | number): Promise<Order> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}`);
    return wrapOrderDetail(response.data);
  }

  async getOrders (
    advertiserId: number | null | undefined,
    pageable: Pageable,
    search: string = '',
    deliveries: string[] = []): Promise<OrdersWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    sort = sort ? sort : 'remainingDay';
    direction = direction ? direction : 'desc';
    let response;
    let sortParam = sort === 'orderNumber' ? sort : `${sort},id`;
    let url = `/v2/adsOrders?search=${encodeURIComponent(search)}&page=${page}&size=${sizePerPage}&sort=${sortParam},${direction}&deliveries=${deliveries}`;
    url = advertiserId ? `${url}&advertiser_id=${advertiserId}` : url;
    response = await this.restClient.get(url);
    return {
      orders: wrapOrderList(response.data.records),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async approveOrder (orderId: number) {
    await this.restClient.put(`/v2/adsOrders/${orderId}/approve`);
  }

  async rejectOrder (orderId: number) {
    await this.restClient.put(`/v2/adsOrders/${orderId}/reject`);
  }

  async createOrder (order: Order) {
    const response = await this.restClient.post('/v2/adsOrders', order);
    return wrapOrderDetail(response.data);
  }

  async updateOrder (order: Order) {
    const response = await this.restClient.put('/v2/adsOrders', order);
    return wrapOrderDetail(response.data);
  }

  async settleOrder (orderId: number) {
    await this.restClient.put(`/v2/adsOrders/settle/${orderId}`);
  }

  async unsettleOrder (orderId: number) {
    await this.restClient.put(`/v2/adsOrders/unsettle/${orderId}`);
  }

  async getOrderOptions (orderType?: OrderType): Promise<Array<SelectOptions>> {
    const params = orderType ? `?adsOrderType=${orderType}` : '';
    const response = await this.restClient.get(`/v2/adsOrders/options${params}`);
    return _.get(response, 'data.records', []);
  }

  async getExistedProjectNames (): Promise<string[]> {
    const response = await this.restClient.get('/v2/adsOrders/final-report/project-names');
    return _.get(response, 'data.records', []);
  }

  async getFinalReportStatus (orderId: number): Promise<FinalReportStatus> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}/final-report/status`);
    return _.get(response, 'data');
  }
}
