import SummaryDetail, { SummaryData, SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { useCallback } from 'react';
import i18n from 'i18n';
import styles from './flowSummarySection.module.scss';

/**
 * @example
 * {
 *   general: {
 *     title: 'General Information',
 *     titlePrefixColor: SummaryTitleColor.GREEN,
 *     content: [
 *       { label: 'Campaign Name', value: 'Campaign 1' },
 *       { label: 'Budget', value: '1000', valueColor: 'green' },
 *       { label: 'Start Date', value: '2021-01-01' },
 *       { label: 'End Date', value: '2021-01-31' }
 *     ]
 *   }
 */
export type SummarySectionData = {
  [sectionKey: string]: {
    title?: string;
    content: SummaryData[];
    titlePrefixColor?: SummaryTitleColor;
  };
};

export type FlowSummarySectionTitleProps = {
  title: string;
  backButtonTitle?: string;
  goStep?: () => void;
};

export const FlowSummarySectionTitle: React.FC<FlowSummarySectionTitleProps> = ({
  title,
  backButtonTitle = i18n.t<string>('flowSummarySection.buttons.back'),
  goStep
}) => {
  return (
    <div className={styles.categoryTitle}>
      {title}
      {goStep && (
        <div className={styles.backBtn} onClick={goStep}>
          {backButtonTitle}
        </div>
      )}
    </div>
  );
};

export type FlowSummarySectionProps =
  ExpandDetails<FlowSummarySectionTitleProps> & {
    summaryData: SummarySectionData;
    renderOtherInfo?: () => React.ReactElement;
  };

export const FlowSummarySection: React.FC<FlowSummarySectionProps> = ({
  title,
  summaryData,
  backButtonTitle,
  renderOtherInfo,
  goStep
}) => {

  const renderSummaryDetails = useCallback(() => {
    return Object.keys(summaryData).map((key) => {
      if (!summaryData[key]) {
        return <div key={key} />;
      }
      const content = summaryData[key].content;
      return (
        <SummaryDetail
          key={key}
          title={summaryData[key].title}
          data={content}
          prefixColor={summaryData[key].titlePrefixColor}
        />
      );
    });
  }, [summaryData]);

  if (!summaryData) {
    return <div />;
  }

  return (
    <section>
      <FlowSummarySectionTitle
        title={title}
        backButtonTitle={backButtonTitle}
        goStep={goStep}
      />
      <div>{renderSummaryDetails()}</div>
      {renderOtherInfo && renderOtherInfo()}
    </section>
  );
};

export type MediaSummaryData = {
  medias: {
    image?: {
      url: string,
      file?: File
    },
    video?: {
      url: string,
      file?: File
    },
    audio?: {
      url: string,
      file?: File
    }
  }
};

export type FlowMediaSummarySectionProps =
  ExpandDetails<FlowSummarySectionTitleProps> & {
    summaryData?: MediaSummaryData;
    MediaComponent?: React.FC<{ mediaSummary: MediaSummaryData }>;
  };

export const FlowMediaSummarySection: React.FC<FlowMediaSummarySectionProps> = ({
  title,
  summaryData,
  MediaComponent,
  goStep
}) => {

  if (!MediaComponent || !summaryData) {
    return <div/>;
  }

  return (
    <section>
      <FlowSummarySectionTitle
        title={title}
        goStep={goStep}
      />
      <div style={{ marginBottom: '10px' }}>
        <MediaComponent mediaSummary={summaryData} />
      </div>
    </section>
  );
};
