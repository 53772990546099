import { ProductGroupFormValue, OutdoorChannel, GeneralChannel, OutdoorSpaceChannel, OpenRtbSpaceChannel, DomainProductGroupFormValue, SpaceProductGroupFormValue, ProductGroupType, SpaceChannel, ProductGroupListDTO, ProductGroupReadDTO, ProductGroupCreateDTO, AUTO_UPDATE_VALUE, ProductGroupUpdateDTO } from './ProductGroup';
import { ProductGroupWebService, RestfulProductGroupWebService } from 'ws/ProductGroupWebService';
import { defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import i18n from 'i18n';
import { AdRequestSourceWebService, RestfulAdRequestSourceWebService } from 'ws/AdRequestSourceWebService';
import { compact, flatMap, isEmpty } from 'lodash';
import { ADDONFEATURE, AddonFeatureType } from 'core/agency/AddonFeature';
import { AddonFeatureManager } from 'core/auth/AddonFeatureManager';
import { AD_TYPE_MAP_CREATIVE_TYPE, AdType } from 'core/rtbCampaign/RtbCampaign';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { DEFAULT_INVENTORY, EmptyLimitationInventorySettings, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';

export interface ProductGroupManager {
  createProductGroup (productGroup: ProductGroupCreateDTO): Promise<void>;
  updateProductGroup (productGroup: ProductGroupUpdateDTO): Promise<void>;
  getProductGroup (): Promise<ProductGroupListDTO[]>;
  getProductGroupById (groupId: number): Promise<ProductGroupReadDTO>;
  getCampaignByGroupId (groupId: number);
  deleteProductGroupById (groupId: number): Promise<void>;
  getInitPropductGroup (groupType: ProductGroupType): DomainProductGroupFormValue | SpaceProductGroupFormValue;
  getSpaceAttributesInventorySettings (
    adFormat: string,
    channel: SpaceChannel | '',
    spaceAttributes: {
      [adFormat: string]: { [attribute: string]: SelectOptions[] }
    }
  ): LimitationInventorySettings[];
  getProductGroupValidAdFormatAndChannel (isSystemAdmin: boolean, addonFeatureManager: AddonFeatureManager): Promise<SelectOptions[]>;
  getRequiredGroupChannelAddonFeaturesOfAdFormatAndChannel (adFormat: string, channel: string): AddonFeatureType | undefined;
  getRequiredCreativesAddonFeaturesOfAdFormatAndChannel (adFormat: string, channel: string): AddonFeatureType[] | undefined;
}

export class DefaultProductGroupManager implements ProductGroupManager {

  constructor (
    private webService: ProductGroupWebService = new RestfulProductGroupWebService(),
    private adRequestSourceWebService: AdRequestSourceWebService = new RestfulAdRequestSourceWebService()
  ) {}

  createProductGroup (productGroup: ProductGroupCreateDTO): Promise<void> {
    return this.webService.createProductGroup(productGroup);
  }
  updateProductGroup (productGroup: ProductGroupUpdateDTO): Promise<void> {
    return this.webService.updateProductGroup(productGroup);
  }
  getProductGroup (): Promise<ProductGroupListDTO[]> {
    return this.webService.getProductGroup();
  }
  getProductGroupById (groupId: number): Promise<ProductGroupReadDTO> {
    return this.webService.getProductGroupById(groupId);
  }
  getCampaignByGroupId (groupId: number) {
    return this.webService.getCampaignByGroupId(groupId);
  }
  deleteProductGroupById (groupId: number): Promise<void> {
    return this.webService.deleteProductGroupById(groupId);
  }
  getInitPropductGroup (groupType: ProductGroupType): DomainProductGroupFormValue | SpaceProductGroupFormValue {
    const basicPart = {
      groupName: '',
      description: '',
      groupValues: [],
      customGroupValues: [],
      agencyIds: []
    };
    if (groupType === ProductGroupType.DOMAIN) {
      return {
        ...basicPart,
        groupType: ProductGroupType.DOMAIN
      };
    }
    return {
      ...basicPart,
      groupType: ProductGroupType.ADSPACE,
      adFormat: '',
      channel: '',
      attributes: {}
    };
  }
  async getProductGroupValidAdFormatAndChannel (isSystemAdmin: boolean, addonFeatureManager: AddonFeatureManager): Promise<SelectOptions[]> {
    const sspSpaceAdFormats = await this.adRequestSourceWebService.getSpaceAdFormats();
    return compact(sspSpaceAdFormats.map(adFormatOption => {
      const adFormat = adFormatOption.value.toString();
      let channels = this.getProductGroupChannels(
        adFormat
      );
      if (isSystemAdmin) {
        return {
          ...adFormatOption,
          options: channels
        };
      }
      const validChannels = channels.filter(channelOption => {
        const channel = channelOption.value.toString();
        let channelAddon = this.getRequiredGroupChannelAddonFeaturesOfAdFormatAndChannel(adFormat, channel);
        if (channelAddon && !addonFeatureManager.isFeatureEnable(channelAddon)) {
          return false;
        }
        let creativeAddons = this.getRequiredCreativesAddonFeaturesOfAdFormatAndChannel(adFormat, channel);
        if (creativeAddons && !addonFeatureManager.isAnyFeatureEnable(...creativeAddons)) {
          return false;
        }
        return true;
      });
      if (validChannels.length > 0) {
        return {
          ...adFormatOption,
          options: validChannels
        };
      }
      return undefined;
    }));
  }
  getRequiredGroupChannelAddonFeaturesOfAdFormatAndChannel (adFormat: string, channel: string): AddonFeatureType | undefined {
    if (channel === 'OpenRTB') {
      return ADDONFEATURE.CHANNEL.RTB;
    }
    if (adFormat === 'Outdoor') {
      if (channel === OutdoorChannel.EdiMax) {
        return ADDONFEATURE.CHANNEL.EDIMAX;
      } else if (channel === OutdoorChannel.PIC) {
        return ADDONFEATURE.CHANNEL.PIC;
      }
    }
    return undefined;
  }
  getRequiredCreativesAddonFeaturesOfAdFormatAndChannel (adFormat: string, channel: string): AddonFeatureType[] | undefined {
    const adFormatChannelAdTypeMap = {
      'Native-OpenRTB': [AdType.DISPLAY, AdType.HAMI_VIDEO_DISPLAY],
      'Banner-OpenRTB': [AdType.DISPLAY, AdType.HAMI_VIDEO_DISPLAY, AdType.HAMI_VIDEO_CONNECTED_TV_IMAGE],
      'Video-OpenRTB': [AdType.VIDEO, AdType.HAMI_VIDEO_VIDEO],
      'Combo-OpenRTB': [AdType.COMBO, AdType.HAMI_VIDEO_COMBO],
      'Straight_Combo-OpenRTB': [AdType.COMBO, AdType.HAMI_VIDEO_COMBO],
      'Outdoor-EdiMax': [AdType.EDIMAX],
      'Outdoor-PIC': [AdType.PIC_SHORT, AdType.PIC_LONG],
      'AdNeon-OpenRTB': [AdType.THIRD_PARTY, AdType.THIRD_PARTY_BOTTOM, AdType.THIRD_PARTY_RECTANGLE],
      'AdNeon_Full_Screen_Rectangle-OpenRTB': [AdType.THIRD_PARTY, AdType.THIRD_PARTY_RECTANGLE],
      'AdNeon_Straight_Combo-OpenRTB': [AdType.THIRD_PARTY, AdType.THIRD_PARTY_RECTANGLE],
      'Long Rectangle-OpenRTB': [AdType.THIRD_PARTY, AdType.THIRD_PARTY_RECTANGLE],
      'Bottom-OpenRTB': [AdType.THIRD_PARTY, AdType.THIRD_PARTY_BOTTOM],
      'Rectangle-OpenRTB': [AdType.THIRD_PARTY, AdType.THIRD_PARTY_RECTANGLE]
    };
    const adFormatChannel = `${adFormat}-${channel}`;
    const relatedAdTypes = adFormatChannelAdTypeMap[adFormatChannel];
    if (!relatedAdTypes) {
      return undefined;
    }

    return flatMap(
      relatedAdTypes.map(adType => {
        const creativeTypes =
          adType in AD_TYPE_MAP_CREATIVE_TYPE
            ? AD_TYPE_MAP_CREATIVE_TYPE[adType]
            : [];
        return creativeTypes.map(
          creativeType => `option_${creativeType}`
        );
      })
    );
  }
  getProductGroupChannels (adFormat: string): SelectOptions[] {
    if (adFormat === '') {
      return [];
    }

    let channels: OutdoorSpaceChannel | OpenRtbSpaceChannel;
    if (adFormat === 'Outdoor') {
      channels = OutdoorChannel;
    } else {
      channels = GeneralChannel;
    }
    return Object.entries(channels).map(([label, value]) => ({ label, value }));
  }

  getSpaceAttributesInventorySettings (
    adFormat: string,
    channel: SpaceChannel | '',
    spaceAttributes: {
      [adFormat: string]: { [attribute: string]: SelectOptions[] }
    }
  ): LimitationInventorySettings[] {
    const defaultInventorySetting: EmptyLimitationInventorySettings = {
      name: DEFAULT_INVENTORY
    };
    if (channel === '' || channel === 'EdiMax') {
      return [defaultInventorySetting];
    }

    const relatedAttributes = spaceAttributes[adFormat];
    if (!relatedAttributes) {
      return [defaultInventorySetting];
    }
    const options = Object.keys(relatedAttributes).map(attribute => ({
      name: attribute,
      title: i18n.t<string>(`productGroup.space.attributes.titles.${attribute}`),
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: 'productGroup.placeholders.search',
      component: SelectComponent,
      cb: async () => relatedAttributes[attribute]
    }));
    return [
      defaultInventorySetting,
      ...options
    ];
  }
}

export function toServerPayload (productGroup: ProductGroupFormValue, allOptions: SelectOptions[]): ProductGroupCreateDTO {

  const translateGroupValues = (groupValues: SelectOptions[]) => {
    return groupValues.map(option => {
      const { label, value } = option;
      const newName = label.replace(`${value},`, '');
      return {
        limitationName: newName,
        limitationValue: value.toString()
      };
    });
  };

  const groupValues = productGroup.groupValues;
  const autoUpdateOption = groupValues.find(option => option.value === AUTO_UPDATE_VALUE);
  const autoUpdate = autoUpdateOption !== undefined;
  const isSpace = productGroup.groupType === ProductGroupType.ADSPACE;
  const ignoreEmptyString = value => isEmpty(value)
    ? undefined
    : value;
  return {
    ...productGroup,
    groupValues: autoUpdate ? translateGroupValues(allOptions) : translateGroupValues(groupValues),
    customGroupValues: translateGroupValues(productGroup.customGroupValues),
    adFormat: isSpace
      ? ignoreEmptyString(productGroup.adFormat)
      : undefined,
    channel: isSpace
      ? ignoreEmptyString(productGroup.channel)
      : undefined,
    attributes: isSpace ? productGroup.attributes : undefined,
    autoUpdate
  };
}
