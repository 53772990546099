import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { ChannelGroupListColumns, useChannelGroupListModel } from './ChannelGroupListModel';
import { ChannelGroupListDTO } from 'core/channelGroup/ChannelGroup';
import i18n from 'i18n';
import { useCallback, useMemo } from 'react';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { SortOrder } from 'react-bootstrap-table-next';
import { Page } from 'components/Page/Page';

export type ChannelGroupListProps = {
  channelGroups: ChannelGroupListDTO[];
};

export const ChannelGroupList: React.FC<ChannelGroupListProps> = ({
  channelGroups
}) => {

  const {
    searchString,
    filteredList,
    columns,
    onSearch
  } = useChannelGroupListModel(channelGroups);

  const listDefaultSorted = useMemo(() => (
    [{
      dataField: ChannelGroupListColumns.ID.toString(),
      order: 'desc'
    }] as [{ dataField: any, order: SortOrder }]
  ), []);

  const renderRowBtns = useCallback((channelGroupList: ChannelGroupListDTO) => {
    return [
      <IconWithTooltip
        key={channelGroupList.id}
        icon={faPencilAlt}
        tooltipProps={{
          id: `channel-groups-edit-tip-${channelGroupList.id}`,
          tooltip: i18n.t<string>('channelGroupList.hints.edit'),
          link: `/system/channel-groups/${channelGroupList.id}/edit`
        }}
      />
    ];
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <Page title={i18n.t<string>('channelGroups.title')}>
        <StickableBootstrapTable
          keyField={ChannelGroupListColumns.ID}
          customPadding={false}
          data={filteredList}
          columns={columns}
          defaultSorted={listDefaultSorted}
          noDataIndication={i18n.t<string>('channelGroupList.labels.noData')}
          defaultSearchString={searchString}
          onHandleSearch={onSearch}
          searchbarPlaceholder={i18n.t<string>('channelGroupList.placeholders.searchbar')}
          renderRowBtns={renderRowBtns}
        />
      </Page>
    </div>
  );
};
