import { BidPriceSetting } from 'components/BidPrice/BidPriceSetting';
import { Currency } from 'core';
import { FieldArray } from 'formik';
import { get } from 'lodash';

export const renderBidPriceFieldArray = () => {
  return <BidPriceFieldArray />;
};

export const BidPriceFieldArray: React.FC = () => (
  <FieldArray name='property.addonProps.campaignBidPrice'>
    {({ form, name }) => {
      const bidPriceSettings = get(form.values, name);
      const priceSettings = bidPriceSettings.map((bidPriceSetting, index) => {
        const optimizes = Object.keys(bidPriceSetting.autoBidCap);
        const type = bidPriceSetting.type;
        return (
          <BidPriceSetting
            key={type}
            fieldName={`${name}.${index}`}
            type={type}
            currency={Currency.NTD}
            optimizes={optimizes}
          />
        );
      });
      return <div style={{ marginTop: '5px' }}>{priceSettings}</div>;
    }}
  </FieldArray>
);
