const MIN_CLOCK_SEQ_AND_NODE = BigInt('0x8080808080808080');

// UUID v1 的起始時間戳：1582-10-15 00:00:00.000
function makeEpoch () {
  const start = new Date(Date.UTC(1582, 9, 15, 0, 0, 0, 0)); // 注意月份是 0 索引
  return start.getTime(); // 以毫秒為單位的 UNIX 時間戳
}

// 將 UNIX 時間戳轉換為 UUID v1 的時間戳
function fromUnixTimestamp (tstamp: number) {
  const START_EPOCH = makeEpoch();
  return BigInt((tstamp - START_EPOCH) * 10000); // 100 纳秒單位
}

// 根據時間戳生成 UUID 的高 64 位（MSB）
function makeMSB (timestamp: bigint) {
  let msb = BigInt(0);

  // 高 32 位
  msb |= (timestamp & BigInt(0xffffffff)) << BigInt(32);

  // 中間 16 位
  msb |= (timestamp & BigInt(0xffff00000000)) >> BigInt(16);

  // 低 12 位
  msb |= (timestamp & BigInt(0xfff000000000000)) >> BigInt(48);

  // 設置版本號為 1
  msb |= BigInt(0x1000);

  return msb;
}

// 將 BigInt UUID 高低 64 位格式化為標準 UUID 字符串
function formatUUID (msb: bigint, lsb: bigint): string {
  const hex = value => value.toString(16).padStart(16, '0'); // 轉為16進制，補零到16位
  const msbHex = hex(msb);
  const lsbHex = hex(lsb);

  // 分段拼接為 UUID 格式：8-4-4-4-12
  return [
    msbHex.slice(0, 8), // time_low
    msbHex.slice(8, 12), // time_mid
    msbHex.slice(12, 16), // time_hi_and_version
    lsbHex.slice(0, 4), // clock_seq_hi_and_reserved + clock_seq_low
    lsbHex.slice(4) // node
  ].join('-');
}

// 主函數：生成 UUID
export function generateUUID (timestamp: number): string {
  const uuidTimestamp = fromUnixTimestamp(timestamp);
  const msb = makeMSB(uuidTimestamp);
  const lsb = MIN_CLOCK_SEQ_AND_NODE;

  // 格式化為標準 UUID 字符串
  return formatUUID(msb, lsb);
}
