import {
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, ViewTrackingTrigger, ViewTrackingType } from 'core/rtbCampaign/RtbCampaign';
import { SupportVideoViewsFormModel } from './SupportVideoViewsFormModel';
import i18n from 'i18n';

export interface VideoFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractVideoFormModel extends SupportVideoViewsFormModel
  implements VideoFormModel {

  supportProgressAdViewObjective: boolean = false;

  get campaignAdType (): AdType {
    return AdType.VIDEO;
  }

  override getThirdPartyTrackingTypeOptions () {
    return [{
      label: i18n.t<string>(
        'campaign.strategyAndTracking.thirdPartyTrackingTypePixel'
      ),
      value: ViewTrackingType.PIXEL
    }];
  }

  override getThirdPartyTriggerTypeOptions () {
    return [{
      label: i18n.t<string>(
        'campaign.strategyAndTracking.impressionTrigger'
      ),
      value: ViewTrackingTrigger.IMPRESSION
    }];
  }
}

export class EditVideoFormModel extends AbstractVideoFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }

  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateVideoFormModel extends AbstractVideoFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
