import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { CreateComboFormModel, EditComboFormModel } from './ComboFormModel';

export class EditHamiVideoComboFormModel extends EditComboFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_COMBO;
  }
}

export class CreateHamiVideoComboFormModel extends CreateComboFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_COMBO;
  }
}
