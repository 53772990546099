import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { Advertiser } from 'core/advertiser/Advertiser';
import { DefaultAdvertiserManager, AdvertiserManager } from 'core/advertiser/AdvertiserManager';
import i18n from 'i18n';
import { AdvertiserMemberListModel, DefaultAdvertiserMemberListModel } from 'components/AdvertiserMemberList/AdvertiserMemberListModel';
import _ from 'lodash';
import { AddonFeatureManager, CompanyMember } from 'core';
import { renderFBPage, renderOfficialWebsite } from './AdvertiserDetailRenderFunctions';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

export interface AdvertiserDetailModel {
  advertiser?: Advertiser;
  readonly advertiserId: string | number;
  readonly state: AdvertiserDetailState;
  readonly event: UpdateEventListener<AdvertiserDetailModel>;
  init (): Promise<void>;
  onUnmount (eventHandler?: number): void;
  getAdvertiserMemberListModel (): AdvertiserMemberListModel;
  switchList (listType: string | null, e): void;
}

export type AdvertiserDetailProps = {
  readonly model: AdvertiserDetailModel;
};

export type AdvertiserDetailState = {
  readonly loading: boolean;
  readonly advertiserViewData: any;
  readonly listType: any;
  readonly members: CompanyMember[];
};

export class DefaultAdvertiserDetailModel implements AdvertiserDetailModel {

  advertiser?: Advertiser;
  advertiserId: string | number;
  event: FireableUpdateEventListener<AdvertiserDetailModel>;
  manager: AdvertiserManager;
  handler?: number;
  advertiserViewData: any;
  loading: boolean;
  advertiserMemberListModel?: AdvertiserMemberListModel;
  listType: string;
  members: CompanyMember[];

  constructor (
    advertiserId: string | number,
    private fbAdAccountOptions: (SelectOptions & { disabled?: boolean })[],
    private fbPageOptions: (SelectOptions & { disabled?: boolean })[],
    private addonFeatureManager: AddonFeatureManager,
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.advertiserId = advertiserId;
    this.manager = manager;
    this.event = new FireableUpdateEventListener<AdvertiserDetailModel>();
    this.loading = false;
    this.members = [];
    this.listType = 'member';
  }

  get state (): AdvertiserDetailState {
    return {
      advertiserViewData: this.advertiserViewData,
      loading: this.loading,
      listType: this.listType,
      members: this.members
    };
  }

  onUnmount (eventHandler?: number) {
    this.advertiser = undefined;
    eventHandler !== undefined && this.event.remove(eventHandler);
  }

  getAdvertiserMemberListModel () {
    if (this.advertiserMemberListModel && this.advertiserMemberListModel.members === this.members) {
      return this.advertiserMemberListModel;
    }
    this.advertiserMemberListModel = new DefaultAdvertiserMemberListModel(this.advertiserId, this.members, this.refreshMember);
    return this.advertiserMemberListModel;
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.advertiser = await this.manager.getAdvertiser(this.advertiserId);
      this.members = await this.manager.getAdvertiserMembers(this.advertiserId);
      this.genAdvertiserViewData();
    } catch (error) {}
    this.updateState(false);
  }

  refreshMember = async (): Promise<void> => {
    this.updateState(true);
    try {
      this.members = await this.manager.getAdvertiserMembers(this.advertiserId);
    } catch (error) {}
    this.updateState(false);
  }

  genAdvertiserViewData () {
    const advertiser = this.advertiser;
    if (!advertiser) {
      this.advertiserViewData = {};
      return;
    }
    const channelAccounts = _.get(advertiser, 'channelAccounts', []);
    const fbAdAccount = channelAccounts.find(account => account.channelType === 'FB');
    const fbPageIds: (string[] | undefined) = _.get(fbAdAccount, 'fbPageIds');
    const fbPages = fbPageIds ? renderFBPage(this.fbPageOptions, fbPageIds) : i18n.t<string>('common.labels.notYetSet');
    const enableFb = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.FB);
    let fbRelatedInfo = enableFb ? {
      fbAdAccountId: fbAdAccount ?
        _.get(this.fbAdAccountOptions.find(option => option.value.toString() === fbAdAccount.accountId), 'label') :
        i18n.t<string>('common.labels.notYetSet'),
      fbPageIds: fbPages
    } : {};
    this.advertiserViewData = _.omitBy({
      basic: _.omitBy({
        id: advertiser.id,
        category: advertiser.category ?
          i18n.t<string>(`tenmaxCategory.labels.${advertiser.category.toLowerCase().replace('-', '_')}`) :
          i18n.t<string>('campaignList.labels.emptyContent'),
        note: advertiser.note ? advertiser.note : i18n.t<string>('campaignList.labels.emptyContent'),
        website: renderOfficialWebsite(advertiser.website),
        ...fbRelatedInfo
      }, _.isUndefined),
      lineBeacon: advertiser.lineBeaconServiceType ? {
        [i18n.t<string>('lineFields.labels.lineBeaconServiceType')]: i18n.t<string>(`lineFields.labels.${advertiser.lineBeaconServiceType}`),
        [i18n.t<string>('lineFields.labels.lineBeaconHardwareId')]: advertiser.lineBeaconHardwareId,
        [i18n.t<string>('lineFields.labels.lineBotChannelSecret')]: advertiser.lineBotChannelSecret,
        [i18n.t<string>('lineFields.labels.lineBotChannelAccessToken')]: advertiser.lineBotChannelAccessToken
      } : undefined,
      other: advertiser.shareSegment && advertiser.shareSegment.length > 0 ? {
        segmentSource: advertiser.shareSegment.map(source => `${source.agencyName} / ${source.advertiserName}`).join(', ')
      } : undefined
    }, _.isUndefined);
  }

  switchList = (listType, e) => {
    e && e.stopPropagation();
    if (listType === null) {
      return;
    }
    this.listType = listType;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
