import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { CreateDisplayFormModel, EditDisplayFormModel } from './DisplayFormModel';

export class EditHamiVideoDisplayFormModel extends EditDisplayFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_DISPLAY;
  }
}

export class CreateHamiVideoDisplayFormModel extends CreateDisplayFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_DISPLAY;
  }
}
