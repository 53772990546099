import { ReportType } from 'core/report/ReportData';
import { VideoReportDataProvider } from './VideoReportDataProvider';
import { ReportDataProvider } from './ReportDataProvider';
import { RebateReportDataProvider } from './RebateReportDataProvider';
import { GeneralReportDataProvider } from './GeneralReportDataProvider';
import { MessageReportDataProvider } from './MessageReportDataProvider';

export function getDataProvider (
  reportType: ReportType,
  onDimensionNameClick: (dimension: string, rowNameValue: string | number) => void,
  onDateClick: (date: string) => void
): ReportDataProvider {
  if (reportType === ReportType.REBATE) {
    return new RebateReportDataProvider(onDimensionNameClick, onDateClick);
  }
  if (reportType === ReportType.MESSAGE) {
    return new MessageReportDataProvider(onDimensionNameClick, onDateClick);
  }
  return reportType === ReportType.PERFORMANCE ?
    new GeneralReportDataProvider(onDimensionNameClick, onDateClick) :
    new VideoReportDataProvider(onDimensionNameClick, onDateClick);
}
