import { ViewTrackingCode, ViewTrackingTrigger, ViewTrackingType } from 'core/rtbCampaign/RtbCampaign';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import i18n from 'i18n';
import { get } from 'lodash';
import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ThirdPartyTrackingCode } from './ThirdPartyTrackingCode';
import { ThirdPartyTrackingInputModal } from './ThirdPartyTrackingInputModal';

export const ThirdPartyTrackingCodes: React.FC<{
  getTrackingTypeOptions: () => {label: string, value: ViewTrackingType}[];
  getTriggerTypeOptions: (trackingType: ViewTrackingType) => {label: string, value: ViewTrackingTrigger}[];
}> = ({ getTrackingTypeOptions, getTriggerTypeOptions }) => {

  const [inputModalProps, setInputModalProps] = useState<{
    viewTrackingCodeToEdit: ViewTrackingCode;
    onChange: (viewTrackingCode: ViewTrackingCode) => void;
  } | undefined>();

  const handleClose = useCallback(() => {
    setInputModalProps(undefined);
  }, []);

  const renderViewTrackingCodes = useCallback(
    ({ name, form, push, remove }: FieldArrayRenderProps) => {
      const viewTrackingCodes = get(form.values, name) as ViewTrackingCode[];
      const onNewButtonClicked = () => {
        const defaultTrackingType = getTrackingTypeOptions()[0].value;
        setInputModalProps({
          viewTrackingCodeToEdit: {
            trackingType: defaultTrackingType,
            originTrackingCode: '',
            trackingCode: '',
            trigger: getTriggerTypeOptions(defaultTrackingType)[0].value
          },
          onChange: (viewTrackingCode: ViewTrackingCode) => {
            push(viewTrackingCode);
            handleClose();
          }
        });
      };
      const newButton = (
        <Button variant='secondary' size='sm' onClick={onNewButtonClicked}>
          {i18n.t<string>('campaign.strategyAndTracking.newThirdPartyTracking')}
        </Button>
      );
      if (!viewTrackingCodes || viewTrackingCodes.length === 0) {
        return newButton;
      }

      const fields = viewTrackingCodes.map((viewTrackingCode, index) => {
        const removeField = () => remove(index);
        const fieldName = `${name}.${index}`;
        const editField = () => {
          setInputModalProps({
            viewTrackingCodeToEdit: viewTrackingCode,
            onChange: (newViewTrackingCode: ViewTrackingCode) => {
              form.setFieldValue(fieldName, newViewTrackingCode);
              handleClose();
            }
          });
        };
        return (
          <ThirdPartyTrackingCode
            key={fieldName}
            viewTrackingCode={viewTrackingCode}
            removeField={removeField}
            editField={editField}
          />
        );
      });
      return (
        <>
          <div>{fields}</div>
          {newButton}
        </>
      );
    },
    [getTrackingTypeOptions, getTriggerTypeOptions, handleClose]
  );

  return (
    <>
      <FieldArray name='viewTrackingCodes' render={renderViewTrackingCodes} />
      {inputModalProps && (
        <ThirdPartyTrackingInputModal
          viewTrackingCode={inputModalProps.viewTrackingCodeToEdit}
          getTrackingTypeOptions={getTrackingTypeOptions}
          getTriggerTypeOptions={getTriggerTypeOptions}
          onChange={inputModalProps.onChange}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
