import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import styles from './page.module.scss';
import classNames from 'classnames/bind';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { Title } from './Title';
import { PropsWithChildren } from 'react';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ElementScrollControl } from 'components/ElementScrollControl/ElementScrollControl';

const cx = classNames.bind(styles);

export type PageProps = {
  title: string;
  minWidth?: number;
  breadcrumbsRoutes?: BreadcrumbsRoute[];
  topAreaWithPadding?: boolean;
  topAreaEndWithShadow?: boolean;
  spaceBetweenTopAreaAndContent?: boolean;
  renderBtns?: () => React.ReactNode;
  renderInTopArea?: () => React.ReactNode;
};

const Section: React.FC<PropsWithChildren<{
  title?: string;
  titleHint?: string,
  inlineTitle?: boolean;
  withShadow?: boolean;
  className?: string;
}>> = ({
  title,
  titleHint,
  inlineTitle,
  withShadow = true,
  children,
  className
}) => {

  return (
    <section className={cx(styles.pageSection, { inlineTitle, withShadow })}>
      {title &&
        <div className={styles.titleArea}>
          <h2>{title}</h2>
          {titleHint &&
            <IconWithTooltip
              icon={faInfoCircle}
              tooltipProps={{
                id: `productGroupTitleTip-${title}`,
                tooltip: titleHint
              }}
            />
          }
        </div>
      }
      <div className={cx(styles.sectionContent, className)}>
        {children}
      </div>
    </section>
  );
};

const Content: React.FC<{
  renderBtns?: () => React.ReactNode;
} & PropsWithChildren> = ({
  children,
  renderBtns
}) => {

  return (
    <div className={styles.mainArea}>
      {children}
      {renderBtns &&
        <div className={styles.buttonArea}>
          {renderBtns()}
        </div>
      }
    </div>
  );
};

export const Page: React.FC<PageProps & React.PropsWithChildren> & { Section: typeof Section, Content: typeof Content } = ({
  title,
  children,
  minWidth,
  breadcrumbsRoutes,
  topAreaWithPadding,
  topAreaEndWithShadow,
  spaceBetweenTopAreaAndContent,
  renderBtns,
  renderInTopArea
}) => {

  const topAreaClass = cx(styles.topArea, {
    topAreaWithPadding: breadcrumbsRoutes ? false : topAreaWithPadding,
    topAreaEndWithShadow,
    spaceBetweenTopAreaAndContent
  });

  return (
    <div className={styles.page}>
      <ElementScrollControl>
        <div style={{ minWidth, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div className={topAreaClass}>
            {
              breadcrumbsRoutes ?
                <TitleWithBreadcrumbs title={title} routes={breadcrumbsRoutes}/> :
                <Title>{title}</Title>
            }
            {renderInTopArea && renderInTopArea()}
          </div>
          <Content renderBtns={renderBtns}>
            {children}
          </Content>
        </div>
      </ElementScrollControl>
    </div>
  );
};

Page.defaultProps = {
  minWidth: 320,
  topAreaWithPadding: true,
  topAreaEndWithShadow: true,
  spaceBetweenTopAreaAndContent: true
};

Page.Section = Section;
Page.Content = Content;
