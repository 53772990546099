import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { CreateVideoFormModel, EditVideoFormModel } from './VideoFormModel';

export class EditHamiVideoVideoFormModel extends EditVideoFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_VIDEO;
  }
}

export class CreateHamiVideoVideoFormModel extends CreateVideoFormModel {

  override get campaignAdType (): AdType {
    return AdType.HAMI_VIDEO_VIDEO;
  }
}
