import { L1ObjectObjective } from 'core/l1Object/L1Object';

export enum L2ObjectOptimizationGoal {
  REACH = 'REACH',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
  VIDEO_VIEWS_REACH = 'VIDEO_VIEWS_REACH',
  IMPRESSIONS = 'IMPRESSIONS',
  CLICKS = 'CLICKS',
  SALES = 'SALES',
  LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS',
  FIXED_VIEWABLE_IMPRESSION = 'FIXED_VIEWABLE_IMPRESSION',
  UNSPECIFIED = 'UNSPECIFIED'
}

export enum BidStrategy {
  LOWEST_COST_WITHOUT_CAP = 'LOWEST_COST_WITHOUT_CAP',
  LOWEST_COST_WITH_BID_CAP = 'LOWEST_COST_WITH_BID_CAP'
  // COST_CAP = 'COST_CAP'
}

export const objectiveOptimizeMap = {
  [L1ObjectObjective.AWARENESS]: [
    L2ObjectOptimizationGoal.REACH,
    L2ObjectOptimizationGoal.VIDEO_VIEWS,
    L2ObjectOptimizationGoal.IMPRESSIONS
  ],
  [L1ObjectObjective.TRAFFIC]: [
    L2ObjectOptimizationGoal.CLICKS
  ],
  [L1ObjectObjective.SALES]: [
    L2ObjectOptimizationGoal.SALES
  ],
  [L1ObjectObjective.UNSPECIFIED]: [
    L2ObjectOptimizationGoal.UNSPECIFIED
  ]
};
