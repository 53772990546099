import { ChannelGroupListDTO } from 'core/channelGroup/ChannelGroup';
import { useMemo, useState } from 'react';
import { getNameIdColumn, getDefaultColumnDescription, ColumnDefinition } from 'components/TableColumn/TableColumn';
import i18n from 'i18n';

export enum ChannelGroupListColumns {
  ID = 'id',
  PUBLISHER_ID = 'publisherIds'
}

export type ChannelGroupListData = {
  readonly searchString: string;
  readonly filteredList: ChannelGroupListDTO[];
  readonly columns: ColumnDefinition[],
  onSearch (searchString: string): void;
};

export const useChannelGroupListModel = (channelGroups: ChannelGroupListDTO[]): ChannelGroupListData => {
  const [searchString, onSearch] = useState<string>('');

  const filteredList = useMemo(() => {
    return channelGroups.filter(
      channelGroup =>
        channelGroup.id.toString().includes(searchString) ||
        channelGroup.name.includes(searchString)
    );
  }, [searchString, channelGroups]);

  const columns = useMemo(
    () => [
      getNameIdColumn(
        getDefaultColumnDescription(
          ChannelGroupListColumns.ID,
          i18n.t<string>('channelGroup.labels.name')
        ),
        channelGroup => `/system/channel-groups/${channelGroup.id}`
      ),
      {
        ...getDefaultColumnDescription(
          ChannelGroupListColumns.PUBLISHER_ID,
          i18n.t<string>('channelGroup.labels.publisherIds')
        ),
        formatter: value => value.join(', ')
      }
    ],
    []
  );

  return {
    searchString,
    filteredList,
    columns,
    onSearch
  };
};
