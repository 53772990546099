import { CampaignBidPriceSetting } from 'core/channelGroup/ChannelGroup';
import styles from './bidPriceSetting.module.scss';
import i18n from 'i18n';
import { useCallback } from 'react';
import { InputGroupField } from 'components/common/form/field/InputGroupField';
import { camelCase } from 'lodash';

interface ChannelGroupBidPriceSettingInfoProps {
  label?: string;
  bidPriceSetting: CampaignBidPriceSetting;
  currency: string;
}

export const ChannelGroupBidPriceSettingInfo: React.FC<ChannelGroupBidPriceSettingInfoProps> = ({
  currency,
  bidPriceSetting
}) => {

  const type = bidPriceSetting.type;

  const renderBidPriceSettings = useCallback((settingType: string) => {
    return Object.keys(bidPriceSetting[settingType]).map(optimize => {
      const value = bidPriceSetting[settingType][optimize];
      return (
        <div key={`${type}.${settingType}.${optimize}`} className={styles.optimizeContainer}>
          <span className={styles.optimize}>
            {i18n.t<string>(`bidPriceSetting.optimize.${optimize.toLowerCase()}`)}
          </span>
          <InputGroupField
            fieldContentWidth={'auto'}
            name={`${type}.${settingType}.${optimize}`}
            prefix={currency}
            type='number'
            value={value}
            as='label'
            prefixBorder={false}
          />
        </div>
      );
    });
  }, [type, currency, bidPriceSetting]);

  return (
    <div className={styles.bidPriceSetting}>
      <span className={styles.title}>
        {i18n.t<string>(`bidPriceSetting.labels.${camelCase(type)}`)}
      </span>
      <fieldset>
        <div className={styles.autoBidCapContainer}>
          <div className={styles.subTitle}>
            {i18n.t<string>('bidPriceSetting.labels.autoBidCapSetting')}
          </div>
          {renderBidPriceSettings('autoBidCap')}
        </div>
        <div className={styles.bidFloorContainer}>
          <div className={styles.subTitle}>
            {i18n.t<string>('bidPriceSetting.labels.bidFloorSetting')}
          </div>
          {renderBidPriceSettings('bidFloor')}
        </div>
      </fieldset>
    </div>
  );
};
