import i18n from 'i18n';
import isUrl from 'is-url';
import { Modal } from 'components/common/Modal/Modal';
import classNames from 'classnames/bind';
import styles from './thirdPartyTrackingCode.module.scss';
import { SelectField } from 'components/common/form/field/SelectField';
import { InputField } from 'components/common/form/field/InputField';
import { ViewTrackingCode, ViewTrackingTrigger, ViewTrackingType } from 'core/rtbCampaign/RtbCampaign';
import { useCallback, useEffect, useMemo, useState } from 'react';

const cssClass = classNames.bind(styles);

export const ThirdPartyTrackingInputModal: React.FC<{
  viewTrackingCode: ViewTrackingCode;
  className?: string;
  getTrackingTypeOptions: () => {label: string, value: ViewTrackingType}[];
  getTriggerTypeOptions: (trackingType: ViewTrackingType) => {label: string, value: ViewTrackingTrigger}[];
  onChange: (viewTrackingCode: ViewTrackingCode) => void;
  handleClose: () => void;
}> = ({
  viewTrackingCode,
  className: parentClass,
  getTrackingTypeOptions,
  getTriggerTypeOptions,
  onChange,
  handleClose
}) => {

  const [trackingType, setTrackingType] = useState<ViewTrackingType>(viewTrackingCode.trackingType);
  const [originTrackingCode, setOriginTrackingCode] = useState<string>(viewTrackingCode.originTrackingCode);
  const [trackingCode, setTrackingCode] = useState<string>(viewTrackingCode.trackingCode);
  const [trigger, setTrigger] = useState<ViewTrackingTrigger>(viewTrackingCode.trigger);
  const [disabled, setDisabled] = useState<boolean>(false);

  const checkURL = useCallback(
    (code: string, type: ViewTrackingType = trackingType) => {
      if (type === ViewTrackingType.PIXEL) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(code, 'text/html');
        const imgTags = doc.getElementsByTagName('img');
        const codeUrl = imgTags.length > 0 ? imgTags[0].getAttribute('src') : code;
        if (isUrl(codeUrl)) {
          setTrackingCode(codeUrl as string);
          setDisabled(false);
          return;
        }
      }
      if (type === ViewTrackingType.JS && code.endsWith('.js') && isUrl(code.trim())) {
        setTrackingCode(code);
        setDisabled(false);
        return;
      }
      setTrackingCode(i18n.t<string>('campaign.strategyAndTracking.noURL'));
      setDisabled(true);
    },
    [trackingType]
  );

  useEffect(() => {
    checkURL(originTrackingCode);
  }, [originTrackingCode, checkURL]);

  const onTrackingTypeChange = useCallback(
    (viewTrackingType: ViewTrackingType) => {
      setTrackingType(viewTrackingType);
      const triggerTypeOptions = getTriggerTypeOptions(viewTrackingType);
      const isTriggerValid = triggerTypeOptions.some(option => option.value === trigger);
      !isTriggerValid && setTrigger(getTriggerTypeOptions(viewTrackingType)[0].value);
      checkURL(originTrackingCode, viewTrackingType);
    },
    [trigger, originTrackingCode, getTriggerTypeOptions, checkURL]
  );

  const onOriginTrackingCodeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setOriginTrackingCode(e.target.value);
    checkURL(e.target.value);
  }, [checkURL]);

  const submit = useCallback(() => {
    onChange({ trackingType, originTrackingCode, trackingCode, trigger });
    handleClose();
  }, [
    originTrackingCode,
    trackingCode,
    trackingType,
    trigger,
    handleClose,
    onChange
  ]);

  const trackingTypeOptions = useMemo(() => {
    return getTrackingTypeOptions();
  }, [getTrackingTypeOptions]);

  const triggerTypeOptions = useMemo(() => {
    return getTriggerTypeOptions(trackingType);
  }, [trackingType, getTriggerTypeOptions]);

  const className = cssClass(parentClass, styles.trackingCode);
  return (
    <Modal
      title={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTracking')}
      primaryButton={{
        title: i18n.t<string>('common.buttons.submit'),
        callback: submit,
        disabled: disabled
      }}
      secondaryButton={{
        title: i18n.t<string>('common.buttons.cancel'),
        callback: handleClose
      }}
      dismiss={handleClose}
    >
      <SelectField
        name='trackingType'
        label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingType')}
        value={trackingType}
        options={trackingTypeOptions}
        onChange={onTrackingTypeChange}
        simpleValue
        fieldContentWidth={336}
        className={styles.select}
      />
      <InputField
        name='originTrackingCode'
        label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingCode')}
        type='text'
        placeholder={trackingType === 'js' ? 'eg. https://dmp.tenmax.io/js/imax.js' : 'eg. https://dmp.tenmax.io/p'}
        value={originTrackingCode}
        onChange={onOriginTrackingCodeChange}
        as='textarea'
        className={styles.originTrackingCode}
        fieldContentWidth={336}
      />
      <InputField
        name='trackingCode'
        label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingPreview')}
        type='text'
        value={trackingCode}
        disabled={true}
        className={className}
        fieldContentWidth={336}
      />
      <SelectField
        name='trigger'
        label={i18n.t<string>('campaign.strategyAndTracking.trigger')}
        value={trigger}
        options={triggerTypeOptions}
        onChange={setTrigger}
        simpleValue
        fieldContentWidth={336}
        className={styles.select}
      />
    </Modal>
  );
};
