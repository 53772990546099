import {
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, ViewTrackingTrigger, ViewTrackingType } from 'core/rtbCampaign/RtbCampaign';
import { SupportVideoViewsFormModel } from './SupportVideoViewsFormModel';
import i18n from 'i18n';

export interface ThirdPartyRectangleFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractThirdPartyRectangleFormModel extends SupportVideoViewsFormModel
  implements ThirdPartyRectangleFormModel {

  supportProgressAdViewObjective: boolean = true;

  get campaignAdType (): AdType {
    return AdType.THIRD_PARTY_RECTANGLE;
  }

  override getThirdPartyTriggerTypeOptions (trackingType: ViewTrackingType) {
    const viewableImpressionOption = {
      label: i18n.t<string>(
        'campaign.strategyAndTracking.viewableImpressionTrigger'
      ),
      value: ViewTrackingTrigger.VIEWABLE_IMPRESSION
    };
    const impressionOption = {
      label: i18n.t<string>(
        'campaign.strategyAndTracking.impressionTrigger'
      ),
      value: ViewTrackingTrigger.IMPRESSION
    };
    return trackingType === ViewTrackingType.JS ? [impressionOption] : [viewableImpressionOption, impressionOption];
  }
}

export class EditThirdPartyRectangleFormModel extends AbstractThirdPartyRectangleFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateThirdPartyRectangleFormModel extends AbstractThirdPartyRectangleFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
