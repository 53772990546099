import { FormConfig } from 'components/common/form/FormConfig';
import React, { useCallback, useEffect, useMemo } from 'react';
import i18n from 'i18n';
import { Form } from 'components/common/form/Form';
import { validateEmpty, validateMinimum } from 'utils/ValidateUtils';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { PmpAdType, PmpFormType, PmpType } from 'core/pmp/Pmp';
import moment from 'moment';
import { camelCase, get, isEmpty, isUndefined, omitBy } from 'lodash';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { useCoreContext } from 'contexts/coreContext';
import { Trans } from 'react-i18next';
import { usePmpDurationValidator } from './PmpDurationValidator';
import styles from './pmp.module.scss';
import { formatPrice } from 'helper/CurrencyHelper';
import { Dayparts, getInitDaypart } from 'components/Dayparts/Dayparts';
import { Dayparts as DaypartsType } from 'core/dayparts/Dayparts';

export type PmpFormProps = {
  pmpFormData: PmpFormType;
  spaceOptions: SelectOptions[];
  agencyOptions: SelectOptions[];
  readonly: boolean;
  onSubmit: (pmp: PmpFormType) => void;
};

const pmpTypeOptions = createSelectOptionsFromEnum(
  PmpType,
  'pmp.pmpTypes.',
  [],
  camelCase
);
const pmpAdTypeOptions = createSelectOptionsFromEnum(
  PmpAdType,
  'pmp.adType.',
  [],
  camelCase
);

export const PmpForm: React.FC<PmpFormProps> = ({
  pmpFormData,
  spaceOptions,
  agencyOptions,
  readonly,
  onSubmit
}) => {
  const core = useCoreContext();
  const currency = get(core, 'accountManager.localeMeta.currency', 'NTD');

  const { loading, fetchPmpWithinDuration, validatePmpDuration } =
    usePmpDurationValidator();

  const initPmpStartTime = pmpFormData.startTime;
  const initPmpEndTime = pmpFormData.endTime;
  useEffect(() => {
    fetchPmpWithinDuration(initPmpStartTime, initPmpEndTime);
  }, [initPmpStartTime, initPmpEndTime, fetchPmpWithinDuration]);

  // 最快明天開始
  const minDate = useMemo(
    () => moment().startOf('day').add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    []
  );

  // 最多從明天算起 60 天內
  const maxDate = useMemo(
    () =>
      moment()
        .startOf('day')
        .add(60, 'days')
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
    []
  );

  const validateForm = useCallback(
    (values: PmpFormType) => {
      const validateDuration = (startTime: string) => {
        let start = moment(startTime);
        if (start.isBefore(minDate)) {
          return i18n.t<string>('pmpForm.errors.createBeforeMinDate');
        }
        return undefined;
      };

      const sponsorshipConflictData = validatePmpDuration(values);
      const spaceError = sponsorshipConflictData
        ? sponsorshipConflictData.spaces.map(space => {
          const target = spaceOptions.find(option => option.value === space);
          return target ? target.label : '';
        })
        : undefined;
      const spaceErrorString = spaceError
        ? `${i18n.t<string>(
            'pmpForm.errors.sponsorshipConflict'
          )}:\n${spaceError.join('\n')}`
        : undefined;

      const durationError = get(sponsorshipConflictData, 'duration');
      const durationErrorString = durationError
        ? `${i18n.t<string>(
            'pmpForm.errors.sponsorshipConflict'
          )}:\n${durationError.join('\n')}`
        : undefined;

      const daypartError = get(sponsorshipConflictData, 'dayPart');
      const daypartErrorString = daypartError
        ? `${i18n.t<string>(
            'pmpForm.errors.sponsorshipConflict'
          )}: ${daypartError.join('、 ')}`
        : undefined;

      return omitBy(
        {
          name: validateEmpty(values.name),
          duration:
            validateDuration(values.startTime) ||
            durationErrorString,
          pmpType: validateEmpty(values.pmpType),
          budget: validateMinimum(values.budget, 1),
          adAgencyIds: omitBy(
            {
              0: validateEmpty(values.adAgencyIds[0])
            },
            isUndefined
          ),
          spaces:
            validateEmpty(values.spaces) ||
            spaceErrorString,
          dayPart: values.dayPart?.enabled
            ? daypartErrorString
            : omitBy(
              {
                enabled: daypartErrorString
              },
                isUndefined
              )
        },
        isEmpty
      );
    },
    [minDate, spaceOptions, validatePmpDuration]
  );

  const renderDaypart = useCallback((props) => <Dayparts {...props} />, []);

  const defaultDaypartValue: DaypartsType & { enabled: boolean } = useMemo(() => ({ ...getInitDaypart(), enabled: false }), []);

  const renderForm = useCallback(
    (formikProps: FormikProps<PmpFormType>) => {
      const { values, setValues, setFieldValue, setFieldTouched } = formikProps;
      const showTimePicker = values.pmpType !== PmpType.BUYOUT;
      const onPmpTypeChange = (type: PmpType) => {
        setValues({
          ...values,
          pmpType: type,
          dayPart: type === PmpType.BUYOUT ? undefined : { ...defaultDaypartValue }
        });
      };

      const setRelatedFieldTouched = () => {
        setFieldTouched('duration', true);
        setFieldTouched('dayPart', true);
        setFieldTouched('dayPart.enabled', true);
        setFieldTouched('spaces', true);
      };

      const onDurationPopOverClosed = async () => {
        await fetchPmpWithinDuration(values.startTime, values.endTime);
        setRelatedFieldTouched();
      };

      const onDaypartSwitchChanged = (checked: boolean) => {
        !checked && setFieldValue('dayPart', { ...defaultDaypartValue });
      };

      const daypartEnabled = values.dayPart?.enabled;

      let start = moment(values.startTime);
      let end = moment(values.endTime);
      const days = end.diff(start, 'days') + 1;
      const budgetHint = i18n.t<string>('pmpForm.hints.budget', {
        min: values.spaces
          ? formatPrice(currency, 3000 * values.spaces.length * days)
          : 0
      });

      const formConfig = new FormConfig.Builder()
        .addSection(
          new FormConfig.SectionBuilder(
            new FormConfig.FieldsBuilder()
              .addFormikInput({
                label: i18n.t<string>('pmp.labels.name'),
                name: 'name',
                disabled: readonly
              })
              .addFormikSelect({
                label: i18n.t<string>('pmp.labels.pmpType'),
                name: 'pmpType',
                simpleValue: true,
                options: pmpTypeOptions,
                disabled: readonly,
                onChange: onPmpTypeChange
              })
              .addFormikDateRangePicker({
                label: i18n.t<string>('pmp.labels.duration'),
                name: 'duration',
                minDate,
                maxDate,
                showTimePicker,
                startDateFormikName: 'startTime',
                endDateFormikName: 'endTime',
                validateOnPopOverClose: true,
                startDatePickerDisabled: readonly,
                endDatePickerDisabled: readonly,
                onPopOverClosed: onDurationPopOverClosed,
                hint: (
                  <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
                    Total
                    <span className='text-dark'>
                      <>{{ days }} days</>
                    </span>
                  </Trans>
                )
              })
              .addFormikSwitch({
                label: i18n.t<string>('pmp.labels.dayparts'),
                name: 'dayPart.enabled',
                onChange: onDaypartSwitchChanged,
                hoverHint: daypartEnabled ? undefined : i18n.t<string>('pmpForm.hints.dayparts')
              }, values.pmpType === PmpType.BUYOUT)
              .addFormikCustom({
                label: '',
                name: 'dayPart',
                onChange: setRelatedFieldTouched,
                render: renderDaypart
              }, !daypartEnabled)
              .addFormikSelect({
                label: i18n.t<string>('pmp.labels.spaces'),
                name: 'spaces',
                isMulti: true,
                simpleValue: true,
                menuPlacement: 'auto',
                options: spaceOptions,
                disabled: readonly,
                onChange: setRelatedFieldTouched
              })
              .addFormikSelect({
                label: i18n.t<string>('pmp.labels.adType'),
                name: 'adType',
                simpleValue: true,
                options: pmpAdTypeOptions,
                disabled: readonly
              })
              .addFormikInputGroup({
                label: i18n.t<string>('pmp.labels.budget'),
                name: 'budget',
                type: 'number',
                prefix: currency,
                disabled: readonly,
                permanentHint: (
                  <span className={styles.hint}>
                    {budgetHint}
                  </span>
                )
              })
              .addFormikSelect({
                label: i18n.t<string>('pmp.labels.agency'),
                name: 'adAgencyIds.0',
                simpleValue: true,
                menuPlacement: 'auto',
                options: agencyOptions,
                disabled: readonly
              })
              .build()
          )
            .withTitle(i18n.t<string>('pmpSetupFlowPage.titles.formSection'))
            .build()
        )
        .build();

      const renderFormBtns = () => (
        <>
          <Form.SubmitButton>
            {i18n.t<string>('common.buttons.completeAndCheck')}
          </Form.SubmitButton>
          <Form.CancelButton />
        </>
      );
      return (
        <Form
          formikProps={formikProps}
          formConfig={formConfig}
          renderFormBtns={renderFormBtns}
        />
      );
    },
    [
      currency,
      minDate,
      maxDate,
      readonly,
      spaceOptions,
      agencyOptions,
      defaultDaypartValue,
      renderDaypart,
      fetchPmpWithinDuration
    ]
  );

  if (!pmpFormData) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={pmpFormData}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur={false}
        enableReinitialize
      >
        {renderForm}
      </Formik>
    </>
  );
};
