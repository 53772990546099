import { renderFields } from 'components/common/form/FormSection';
import { Advertiser } from 'core/advertiser/Advertiser';
import { useLineBeaconFormikFields } from 'hooks/useLineBeaconFormikFields';

export const AdvertiserLineFields: React.FC = () => {

  const fields = useLineBeaconFormikFields<Advertiser>();

  return <div>{renderFields(fields)}</div>;
};
