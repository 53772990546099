import React from 'react';
import styles from './tips.module.scss';
import { compact } from 'lodash';
const Tips = props => {
  if (!props.children) return null;

  const validChild: React.ReactElement[] = compact(React.Children.map(
    props.children,
    child => {
      if (React.isValidElement(child) || typeof child === 'string') {
        return child;
      }
      return;
    }
  ));

  return <span className={[styles.wording, 'errorTip'].join(' ')}>{validChild}</span>;
};

export default Tips;
