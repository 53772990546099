import {
  AuditLogWithPagination,
  AuditLogWebService,
  RestfulAuditLogWebService
} from 'ws/AuditLogWebService';
import { Pageable } from 'ws/Pageable';
import { AUDITLOG_COLUMN } from './AuditLog';

export interface AuditLogManager {
  getOrderAndL1ObjectAuditLogs (
    orderId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination>;
  getOrderAndL1ObjectAuditLogsFields (
    orderId: number,
    l1ObjectIds: (number | string)[]
  ): Promise<string[]>;
  getCampaignAuditLog (
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination>;
  getCampaignAuditLogFields (
    campaignIds: (number | string)[]
  ): Promise<string[]>;
  getAgencyAuditLog (
    agencyId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination>;
  getAgencyAuditLogFields (
    agencyId: number
  ): Promise<string[]>;
}

const columnSortFieldMap = {
  [AUDITLOG_COLUMN.DATE]: 'id',
  [AUDITLOG_COLUMN.SOURCE_INFO]: 'srcID',
  [AUDITLOG_COLUMN.EMAIL]: 'userId',
  [AUDITLOG_COLUMN.EVENT]: 'funcType'
};

export class DefaultAuditLogManager implements AuditLogManager {

  constructor (
    private webService: AuditLogWebService = new RestfulAuditLogWebService()
  ) {}

  replaceColumnNameToSortField (pageable: Pageable): Pageable {
    return {
      ...pageable,
      sort: pageable.sort ? columnSortFieldMap[pageable.sort] : undefined
    };
  }

  async getOrderAndL1ObjectAuditLogs (
    orderId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getOrderAndL1ObjectAuditLogs(orderId, this.replaceColumnNameToSortField(pageable), searchString);
    return auditLogWithPagination;
  }

  async getOrderAndL1ObjectAuditLogsFields (
    orderId: number,
    l1ObjectIds: (number | string)[]
  ): Promise<string[]> {
    return this.webService.getOrderAndL1ObjectAuditLogFields(orderId, l1ObjectIds);
  }

  async getCampaignAuditLog (
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getCampaignAuditLog(this.replaceColumnNameToSortField(pageable), searchString);
    return auditLogWithPagination;
  }

  async getCampaignAuditLogFields (
    campaignIds: (number | string)[]
  ): Promise<string[]> {
    return this.webService.getCampaignAuditLogFields(campaignIds);
  }

  async getAgencyAuditLog (
    agencyId: number,
    pageable: Pageable,
    searchString: string
  ): Promise<AuditLogWithPagination> {
    const auditLogWithPagination = await this.webService.getAgencyAuditLog(agencyId, this.replaceColumnNameToSortField(pageable), searchString);
    return auditLogWithPagination;
  }

  async getAgencyAuditLogFields (
    agencyId: number
  ): Promise<string[]> {
    return this.webService.getAgencyAuditLogFields(agencyId);
  }
}
