import { useLineBeaconFormikFields } from 'hooks/useLineBeaconFormikFields';
import { OutdoorFormModel } from '../OutdoorFormModel';
import { useCallback, useMemo, useState } from 'react';
import { FormConfig } from 'components/common/form/FormConfig';
import { useFormikContext } from 'formik';
import { BasicOutdoorCreativeProperties } from 'core/creative/OutdoorCreative';
import { isEmpty } from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import styles from './outdoorFields.module.scss';
import i18n from 'i18n';

export const useOutdoorLineFields = <T extends OutdoorFormModel<T>> (formModel: T) => {

  const { values, setFieldValue } = useFormikContext<{
    typeProperties: BasicOutdoorCreativeProperties & { linePushMessage: string };
  }>();

  const lineFormFields = useLineBeaconFormikFields('typeProperties');
  const {
    linePushMessage
  } = values.typeProperties;

  const [showLineConfig, setShowLineConfig] = useState<boolean>(!isEmpty(linePushMessage));

  const validate = useCallback((value: string) => {
    if (!showLineConfig) {
      return;
    }
    return validateEmpty(value);
  }, [showLineConfig]);

  const onSwitchChange = useCallback((event) => {
    const value = event.target.checked;
    setShowLineConfig(value);
    if (!value) {
      setFieldValue('typeProperties.linePushMessage', undefined);
      setFieldValue('typeProperties.lineBeaconServiceType', undefined);
      setFieldValue('typeProperties.lineBeaconHardwareId', undefined);
      setFieldValue('typeProperties.lineBotChannelSecret', undefined);
      setFieldValue('typeProperties.lineBotChannelAccessToken', undefined);
    } else {
      const {
        lineBeaconServiceType,
        lineBeaconHardwareId,
        lineBotChannelSecret,
        lineBotChannelAccessToken
      } = formModel.lineBeaconConfig;
      setFieldValue('typeProperties.lineBeaconServiceType', lineBeaconServiceType);
      setFieldValue('typeProperties.lineBeaconHardwareId', lineBeaconHardwareId);
      setFieldValue('typeProperties.lineBotChannelSecret', lineBotChannelSecret);
      setFieldValue('typeProperties.lineBotChannelAccessToken', lineBotChannelAccessToken);
    }
  }, [formModel.lineBeaconConfig, setFieldValue]);

  const fields = useMemo(() => {
    const builder = new FormConfig.FieldsBuilder().addSwitch({
      label: i18n.t<string>('lineFields.labels.lineBeaconConfigSwitch'),
      name: 'lineConfigSwith',
      value: showLineConfig,
      onChange: onSwitchChange
    });

    if (!showLineConfig) {
      return builder.build();
    }
    builder.addFields(lineFormFields.map(field => ({
      ...field,
      props: {
        ...field.props,
        validate
      }
    })));
    builder.addFormikTextarea({
      label: i18n.t<string>('lineFields.labels.linePushMessage'),
      name: 'typeProperties.linePushMessage',
      className: styles.linePushMessage,
      validate
    });

    return builder.build();
  }, [lineFormFields, showLineConfig, validate, onSwitchChange]);

  return fields;
};
