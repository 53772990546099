import themeConfig from './themeConfig';

const cloudStorageConfig = process.env.REACT_APP_CLOUD_STORAGE_CONFIG ? JSON.parse(process.env.REACT_APP_CLOUD_STORAGE_CONFIG) : {};

const config = {
  ...themeConfig,
  env: process.env.REACT_APP_ENV,
  cloudStorageConfig,
  shortenUrlLength: process.env.REACT_APP_SHORTEN_URL_LENGTH,
  audienceSizeLowerBound: process.env.REACT_APP_AUDIENCE_SIZE_LOWERBOUND,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  tenmaxLineDomain: process.env.REACT_APP_TENMAX_LINE_DOMAIN
};

export default config;
