import { AxiosInstance } from 'axios';
import { ChannelGroupDTO, ChannelGroupFormData, ChannelGroupListDTO } from 'core/channelGroup/ChannelGroup';
import { get, omit } from 'lodash';
import client from './RestClient';

export interface ChannelGroupWebService {
  getChannelGroups (): Promise<ChannelGroupListDTO[]>;
  getChannelGroup (id: number): Promise<ChannelGroupDTO>;
  editChannelGroup (id: number, formData: ChannelGroupFormData): Promise<void>;
}
export class RestfulChannelGroupWebService implements ChannelGroupWebService {

  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  wrapChannelGroup<T extends { channelId: number }> (json: T) {
    return {
      ...omit(json, 'channelId'),
      id: json.channelId
    };
  }

  async getChannelGroups () {
    const response = await this.restClient.get(`/channel-groups`);
    return get(response, 'data.records', []).map(this.wrapChannelGroup) as ChannelGroupListDTO[];
  }

  async getChannelGroup (id: number) {
    const response = await this.restClient.get(`/channel-groups/${id}`);
    return this.wrapChannelGroup(response.data) as ChannelGroupDTO;
  }

  async editChannelGroup (id: number, formData: ChannelGroupFormData) {
    await this.restClient.put(`/channel-groups`, {
      channelId: id,
      ...formData
    });
  }
}
