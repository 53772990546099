import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './thirdPartyTrackingCode.module.scss';
import { ViewTrackingCode } from 'core/rtbCampaign/RtbCampaign';

type ThirdPartyTrackingCodeProps = {
  viewTrackingCode: ViewTrackingCode,
  editField: () => void;
  removeField: () => void;
};

export const ThirdPartyTrackingCode: React.FC<ThirdPartyTrackingCodeProps> = ({
  viewTrackingCode,
  editField,
  removeField
}: ThirdPartyTrackingCodeProps) => {

  return (
    <div>
      <div className={styles.row}>
        <Form.Control
          name='trackingCode'
          type='text'
          value={viewTrackingCode.trackingCode}
          disabled={true}
          className={styles.trackingCode}
        />
        <Button
          variant='secondary'
          size='sm'
          onClick={editField}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </Button>
        <Button
          variant='secondary'
          size='sm'
          onClick={removeField}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    </div>
  );
};
