import { BlobWebService, RestfulBlobWebService } from 'ws/BlobWebService';
import { v4 as uuidv4 } from 'uuid';
import config from 'config';
import { GCPCloudStorageWebService, RestfulGCPCloudStorageWebService } from 'ws/GCPCloudStorageWebService';

export interface CloudStorageManager {
  uploadVideoToCloud (file: File): Promise<string | undefined>;
  uploadFileToCloud (file: File, rootPath?: string): Promise<string>;
}

const replaceFileNameWithUUID = (originFileName) => {
  const uuid = uuidv4();
  const splitFileName = originFileName.split('.');
  const extension = splitFileName.length > 1 ? splitFileName.pop() : '';
  return `${uuid}.${extension}`;
};

export class AzureBlobManager implements CloudStorageManager {

  creativeContainer: string = 'upload';
  mediaAccount: string | undefined;

  constructor (
    private webService: BlobWebService = new RestfulBlobWebService()
  ) {
    this.creativeContainer = config.cloudStorageConfig.creative_container;
    if (config.cloudStorageConfig.platform === 'azure') {
      this.mediaAccount = config.cloudStorageConfig.media_account;
    }
  }

  async uploadFileToCloud (file: File, rootPath: string = 'tmp') {
    const sasToken = await this.webService.getBlobSASToken();
    const uuid = uuidv4();
    const containerName = this.creativeContainer;
    const containerPath = `${containerName}/${rootPath}/${uuid}`;
    const splitFileName = file.name.split('.');
    const extension = splitFileName.length > 1 ? splitFileName.pop() : '';
    const fileName = `${uuid.split('-')[0]}.${extension}`;
    const params = {
      file,
      containerPath,
      fileName,
      azure: {
        accountId: 'tenmaxsgdspprodcreative',
        accountSAS: sasToken
      }
    };
    await this.webService.uploadFileToCloud(params);
    return `https://tenmaxsgdspprodcreative.blob.core.windows.net/${containerPath}/${fileName}`;
  }

  async uploadVideoToCloud (file: File) {
    const targetFileName = replaceFileNameWithUUID(file.name);
    const response = await this.webService.getMediaServiceToken(targetFileName);
    if (this.mediaAccount) {
      const params = {
        file,
        containerPath: response.container,
        fileName: targetFileName,
        azure: {
          accountId: this.mediaAccount,
          accountSAS: response.token
        }
      };
      await this.webService.uploadFileToCloud(params);
      return response.assetId;
    }
  }
}

export class GCPCloudStorageManager implements CloudStorageManager {

  constructor (
    private webService: GCPCloudStorageWebService = new RestfulGCPCloudStorageWebService()
  ) {}

  async uploadFileToCloud (file: File, rootPath: string = 'tmp') {
    const token = await this.webService.getGCSToken();
    const uuid = uuidv4();
    const splitFileName = file.name.split('.');
    const extension = splitFileName.length > 1 ? splitFileName.pop() : '';
    const filePath = `${config.cloudStorageConfig.creative_container}/${rootPath}/${uuid}/${uuid.split('-')[0]}.${extension}`;
    await this.webService.uploadFileToCloud(token, file, config.cloudStorageConfig.bucket, filePath);
    return `https://storage.googleapis.com/${config.cloudStorageConfig.bucket}/${filePath}`;
  }

  async uploadVideoToCloud (file: File) {
    const token = await this.webService.getGCSToken();
    const uuid = uuidv4();
    const splitFileName = file.name.split('.');
    const extension = splitFileName.length > 1 ? splitFileName.pop() : '';
    const filePath = `${config.cloudStorageConfig.creative_container}/${uuid}/${uuid.split('-')[0]}.${extension}`;
    await this.webService.uploadFileToCloud(token, file, config.cloudStorageConfig.media_bucket, filePath);
    return filePath;
  }
}

export class HamiVideoGCPCloudStorageManager implements CloudStorageManager {

  constructor (
    private webService: GCPCloudStorageWebService = new RestfulGCPCloudStorageWebService()
  ) {}

  async uploadFileToCloud (file: File, rootPath: string = 'tmp') {
    const token = await this.webService.getGCSToken();
    const uuid = uuidv4();
    const splitFileName = file.name.split('.');
    const extension = splitFileName.length > 1 ? splitFileName.pop() : '';
    const filePath = `${config.cloudStorageConfig.hami_container}/${rootPath}/${uuid}/${uuid.split('-')[0]}.${extension}`;
    await this.webService.uploadFileToCloud(token, file, config.cloudStorageConfig.hami_bucket, filePath);
    return `https://storage.googleapis.com/${config.cloudStorageConfig.hami_bucket}/${filePath}`;
  }

  async uploadVideoToCloud (file: File) {
    const token = await this.webService.getGCSToken();
    const uuid = uuidv4();
    const splitFileName = file.name.split('.');
    const extension = splitFileName.length > 1 ? splitFileName.pop() : '';
    const filePath = `${config.cloudStorageConfig.hami_media_container}/${uuid}/${uuid.split('-')[0]}.${extension}`;
    await this.webService.uploadFileToCloud(token, file, config.cloudStorageConfig.hami_bucket, filePath);
    return filePath;
  }
}

export const DefaultCloudStorageManager = config.cloudStorageConfig.platform === 'azure' ? AzureBlobManager : GCPCloudStorageManager;
