import { FormConfig } from 'components/common/form/FormConfig';
import { LineBeaconServiceType } from 'core/advertiser/Advertiser';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import config from 'config';

export const useLineBeaconFormikFields = <
  T extends {
    lineBeaconServiceType: string;
    lineBeaconHardwareId: string;
  }
>(
  namePrefix: string = ''
) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<T>();

  const getFieldName = useCallback(
    (name: string) => {
      return namePrefix ? `${namePrefix}.${name}` : name;
    },
    [namePrefix]
  );

  const lineBeaconServiceType = get(
    values,
    getFieldName('lineBeaconServiceType')
  );
  const lineBeaconHardwareId = get(values, getFieldName('lineBeaconHardwareId'));
  const lineBotChannelSecret = get(values, getFieldName('lineBotChannelSecret'));
  const lineBotChannelAccessToken = get(
    values,
    getFieldName('lineBotChannelAccessToken')
  );

  const generateWebhook = useCallback(() => {
    const tenmaxLineDomain = config.tenmaxLineDomain;
    const url = `${tenmaxLineDomain}/webhook?hwid=${lineBeaconHardwareId}`;
    navigator.clipboard.writeText(url);
    toast.success(i18n.t<string>('lineFields.hints.generateWebhookSuccess'));
  }, [lineBeaconHardwareId]);

  const generateBeaconAPIUrl = useCallback(() => {
    const tenmaxLineDomain = config.tenmaxLineDomain;
    const url = `${tenmaxLineDomain}/api/beacon?hwid=${lineBeaconHardwareId}`;
    navigator.clipboard.writeText(url);
    toast.success(i18n.t<string>('lineFields.hints.generateBeaconUrlSuccess'));
  }, [lineBeaconHardwareId]);

  const fields = useMemo(() => {
    const builder = new FormConfig.FieldsBuilder();
    builder
      .addFormikSelect({
        label: i18n.t<string>('lineFields.labels.lineBeaconServiceType'),
        name: getFieldName('lineBeaconServiceType'),
        simpleValue: true,
        isClearable: true,
        options: [
          { label: i18n.t<string>('lineFields.labels.tenmaxSupport'), value: LineBeaconServiceType.TENMAX },
          { label: i18n.t<string>('lineFields.labels.advertiserSelfServe'), value: LineBeaconServiceType.SELF_SERVE }
        ],
        onChange: (type: LineBeaconServiceType) => {
          const defaultLineBeaconHardwareId = !type ? undefined : lineBeaconHardwareId;
          const defaultLineBotChannelSecret = type === LineBeaconServiceType.TENMAX ? '' : undefined;
          const defaultLineBotChannelAccessToken = type === LineBeaconServiceType.TENMAX ? '' : undefined;
          setFieldValue(getFieldName('lineBeaconHardwareId'), defaultLineBeaconHardwareId);
          setFieldValue(getFieldName('lineBotChannelSecret'), defaultLineBotChannelSecret);
          setFieldValue(getFieldName('lineBotChannelAccessToken'), defaultLineBotChannelAccessToken);
          setFieldTouched(getFieldName('lineBeaconHardwareId'), false);
          setFieldTouched(getFieldName('lineBotChannelSecret'), false);
          setFieldTouched(getFieldName('lineBotChannelAccessToken'), false);
        }
      });

    if (!lineBeaconServiceType) {
      return builder.build();
    }

    builder.addFormikInput({
      label: i18n.t<string>('lineFields.labels.lineBeaconHardwareId'),
      name: getFieldName('lineBeaconHardwareId'),
      placeholder: 'Enter Line Beacon Hardware ID'
    });

    const isTenMaxSupport = lineBeaconServiceType === LineBeaconServiceType.TENMAX;
    const canGenerateWebhook = isTenMaxSupport
      ? !isEmpty(lineBeaconHardwareId) &&
        !isEmpty(lineBotChannelSecret) &&
        !isEmpty(lineBotChannelAccessToken)
      : !isEmpty(lineBeaconHardwareId) &&
        !isEmpty(lineBeaconServiceType);
    if (isTenMaxSupport) {
      builder
        .addFormikInput({
          label: i18n.t<string>('lineFields.labels.lineBotChannelSecret'),
          name: getFieldName('lineBotChannelSecret'),
          placeholder: 'Enter Line Bot Channel Secret'
        })
        .addFormikInput({
          label: i18n.t<string>('lineFields.labels.lineBotChannelAccessToken'),
          name: getFieldName('lineBotChannelAccessToken'),
          placeholder: 'Enter Line Bot Channel Access Token'
        })
        .addCustom({
          name: 'generateUrlBtn',
          label: i18n.t<string>('lineFields.labels.generateWebhook'),
          render: () => (
            <Button
              variant='secondary'
              disabled={!canGenerateWebhook}
              onClick={generateWebhook}
              style={{ height: '35px' }}
            >
              {i18n.t<string>('lineFields.buttons.generateWebhook')}
            </Button>
          )
        });
    } else {
      builder.addCustom({
        name: 'generateUrlBtn',
        label: i18n.t<string>('lineFields.labels.generateBeaconAPIUrl'),
        render: () => (
          <Button
            variant='secondary'
            disabled={!canGenerateWebhook}
            onClick={generateBeaconAPIUrl}
            style={{ height: '35px' }}
          >
            {i18n.t<string>('lineFields.buttons.generateBeaconAPIUrl')}
          </Button>
        )
      });
    }

    return builder.build();
  }, [
    lineBeaconHardwareId,
    lineBeaconServiceType,
    lineBotChannelSecret,
    lineBotChannelAccessToken,
    generateWebhook,
    generateBeaconAPIUrl,
    getFieldName,
    setFieldValue,
    setFieldTouched
  ]);

  return fields;
};
