import React from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';

type MonthPickerProps = {
  date: string;
  minDate?: string;
  maxDate?: string;
  onChange: (date: string) => void;
};

export const MonthPicker: React.FC<MonthPickerProps> = props => {

  const handleChange = (date: Date) => {
    const dateString = formatDate(date);
    props.onChange(dateString);
  };

  const formatDate = (date: Date) => {
    const clientTimeZone = moment.tz.guess();
    return moment.tz(date, clientTimeZone).format('YYYY-MM');
  };

  return (
    <DatePicker
      selected={new Date(props.date)}
      onChange={handleChange}
      dateFormat='yyyy-MM'
      minDate={props.minDate ? new Date(props.minDate) : undefined}
      maxDate={props.maxDate ? new Date(props.maxDate) : undefined}
      showMonthYearPicker
    />
  );
};
