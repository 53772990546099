import { CreateImageFormModel, ImageFormModel } from './ImageFormModel';

const initLimitations = {
  include: [{
    type: 'device',
    op: 'inc',
    value: [{
      label: 'Connected_tv',
      value: '3'
    }, {
      label: 'Connected_device',
      value: '6'
    }, {
      label: 'Set_top_box',
      value: '7'
    }]
  }],
  exclude: [],
  nonPreferred: [],
  other: [],
  preferred: []
};

const validWidthHeightMap = {
  1200: [400]
};

export class HamiVideoConnectedTVImageFormModel extends ImageFormModel {

  override async init () {
    this.validWidthHeightMap = validWidthHeightMap;
  }

  override getInitLimitations () {
    return initLimitations;
  }
}

export class CreateHamiVideoConnectedTVImageFormModel extends CreateImageFormModel {

  override async init () {
    this.validWidthHeightMap = validWidthHeightMap;
  }

  override getInitLimitations () {
    return initLimitations;
  }
}
