import React from 'react';
import styles from './videoCreativeDetail.module.scss';
import _ from 'lodash';
import { ThirdPartyVideoPreview } from 'components/common/Video/ThirdPartyVideoPreview';
import { CreativeListRecord } from 'core/creative/Creative';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
import videoPlaceholder from 'assets/video-placeholder.svg';
import classNames from 'classnames/bind';
import i18n from 'i18n';

const cx = classNames.bind(styles);

export const VideoCreativeDetail: React.FunctionComponent<{creative: CreativeListRecord}> = (props) => {
  const MAX_WIDTH = 600;
  const MAX_HEIGHT = 400;
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const isThirdParty = creativeValue.adServingUrl ? true : false;
  const source = isThirdParty ? creativeValue.adServingUrl as string : creativeValue.videoUrl as string;
  let width = creativeValue.w as number;
  let height = creativeValue.h as number;
  const ratio = height / width;
  if (width > MAX_WIDTH) {
    width = MAX_WIDTH;
    height = width * ratio;
  }
  if (height > MAX_HEIGHT) {
    height = MAX_HEIGHT;
    width = height / ratio;
  }

  const renderIndicator = ({ activeIndex, handleSelect }) => {
    const videoChildren = props.creative.videoChildren;
    if (!videoChildren) {
      return <div />;
    }
    const indicators = Object.keys(videoChildren).map((device, index) => {
      const buttonClassName = cx('button', {
        active: index === activeIndex
      });
      return (
        <span
          key={device}
          className={buttonClassName}
          onClick={_.partial(handleSelect, index)}
        >
          {device}
        </span>
      );
    });
    return (
      <div className={styles.indicators}>
        {indicators}
      </div>
    );
  };

  const renderVideo = (source?: string) => {
    if (!source) {
      return (
        <div className={styles.videoPlaceholder}>
          <img src={videoPlaceholder} alt={'preview area'} />
          <span>
            {i18n.t<string>('videoCreativePreivew.labels.generating')}
          </span>
        </div>
      );
    }
    return (
      <video controls width={width} height={height}>
        <source src={source} type='video/mp4' />
      </video>
    );
  };

  const renderVideoOfAllDevices = () => {
    const videoChildren = props.creative.videoChildren;
    if (!videoChildren) {
      return renderVideo(source);
    }
    return (
      <Slider
        model={
          new DefaultSliderModel(width + 10, height + 10, true, false, 0, false)
        }
        renderIndicator={renderIndicator}
      >
        {Object.keys(videoChildren).map(device => (
          <div key={device}>
            {renderVideo(videoChildren[device].videoUrl)}
          </div>
        ))}
      </Slider>
    );
  };

  return (
    <div className={styles.videoCreativeDetailContainer}>
      <div className={styles.videoCreativeDetail}>
        {isThirdParty ? (
          <div className={styles.thirdVideoDetail}>
            <ThirdPartyVideoPreview url={source} canClick={true} />
          </div>
        ) : (
          renderVideoOfAllDevices()
        )}
      </div>
    </div>
  );
};
