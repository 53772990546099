import { Actor, AddonFeatureManager, AgencyManager, DefaultAgencyManager, LocaleMeta } from 'core';
import { ReportData, ReportDimension, ReportGran, ReportType } from 'core/report/ReportData';
import { DefaultReportManager, ReportManager } from 'core/report/ReportManager';
import i18n from 'i18n';
import { AbstactReportContentModel } from '../ReportContentModel';
import moment from 'moment-timezone';
import { getDataProvider } from 'components/ReportTable/ReportDataProviderFactory';
import { toast } from 'react-toastify';
import { cloneDeep, defaultTo } from 'lodash';

export class ReconciliationReportModel extends AbstactReportContentModel {

  agencyProvideReportToWorkflow?: SelectOptions[];

  constructor (
    actor: Actor | null,
    localeMeta: LocaleMeta | undefined,
    updateSearchPath: (newSearchPath, replace) => void,
    addonFeatureManager: AddonFeatureManager,
    reportManager: ReportManager = new DefaultReportManager(),
    private agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(
      actor,
      localeMeta,
      updateSearchPath,
      addonFeatureManager,
      reportManager
    );
    this.reportType = this.defaultReportType;
    this.dimension = this.defaultReportDimension;
    this.gran = this.defaultReportGran;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick);
    this.from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
  }

  get title () {
    return i18n.t<string>('report.labels.reconciliationTitle');
  }

  get needSpecifyAgency () {
    return this.isSysAdmin;
  }

  get defaultReportType () {
    return ReportType.REBATE;
  }

  get defaultReportDimension () {
    return ReportDimension.MONTH;
  }

  get defaultReportGran () {
    return ReportGran.MONTH;
  }

  get reportTypes () {
    return [{
      label: i18n.t<string>(`report.labels.${ReportType.REBATE}Type`),
      value: ReportType.REBATE
    }];
  }

  get reportGrans () {
    return Object.values(ReportGran)
      .filter(reportGran => reportGran !== ReportGran.HOUR)
      .map(reportGran => {
        return {
          label: i18n.t<string>(`report.labels.${reportGran}`),
          value: reportGran
        };
      }
      );
  }

  get validDimensions () {
    return [ReportDimension.MONTH, ...this.state.tableDimensions];
  }

  async getReportData (): Promise<ReportData> {
    if (this.isSysAdmin && !this.filter[ReportDimension.AGENCY]) {
      const reportData = cloneDeep(this.defaultReportData);
      if (this.isDateDimension(this.dimension)) {
        this.fillDateRecords(reportData);
      }
      const cacheKey = this.isDateDimension(this.dimension) ? ReportDimension.DAY : this.dimension;
      this.cachedReportQuery[cacheKey] = {
        searchPath: this.searchPath,
        result: reportData
      };
      return reportData;
    }
    return super.getReportData();
  }

  async fetchFilterOptions (filterType, filters = this.filter) {
    await super.fetchFilterOptions(filterType, filters);
    if (filterType === ReportDimension.AGENCY && this.agencyProvideReportToWorkflow) {
      try {
        this.filterOptions[filterType] = this.filterOptions[filterType].filter(option => defaultTo(this.agencyProvideReportToWorkflow, []).find(agency => agency.value.toString() === option.value.toString()));
      } catch (e) {}
    }
  }

  download = async () => {
    const agencyId = this.isSysAdmin ? this.filter[ReportDimension.AGENCY] : undefined;
    this.updateState(true);
    try {
      await this.reportManager.downloadRebateReport(
        this.state.from,
        agencyId
      );
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  canFilterSelect = (filterName: string) => {
    if (this.isSysAdmin) {
      return filterName === ReportDimension.AGENCY;
    }
    return true;
  }

  canFilterRemove = (filterName: string) => {
    return filterName !== ReportDimension.AGENCY;
  }

  initReportData = async () => {
    this.reportType = this.defaultReportType;
    this.dimension = this.defaultReportDimension;
    this.gran = this.defaultReportGran;
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick);
    this.filter = {};
    this.from = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.searchString = '';
    this.selectedTagFilter = [];
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    this.setUpReportTableData();
  }

  async updateReportData (params) {
    if (!this.agencyProvideReportToWorkflow && this.isSysAdmin) {
      try {
        this.agencyProvideReportToWorkflow = await this.agencyManager.getAgenciesOptions('company.provideReportToWorkflow');
      } catch (e) {}
    }
    await super.updateReportData(params);
  }

  onDateClick = async (date) => {
    this.from = moment(date).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    this.to = moment(date).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    if (this.gran === ReportGran.MONTH) {
      this.gran = ReportGran.DAY;
      this.dimension = ReportDimension.DAY;
    }
    this.updateSearchPath(false);
  }
}
