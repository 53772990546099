import { ModalState } from 'containers/Common/ModalStateFactory';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import i18n from 'i18n';
import { DraftSummaryStateContent } from './DraftSummaryStateContent';
import { FbAdSetDraftSummaryStateContentModel, RtbCampaignDraftSummaryStateContentModel, RetailCampaignDraftSummaryStateContentModel, DefaultDraftSummaryStateContentModel, EdiMaxCampaignDraftSummaryStateContentModel, PICCampaignDraftSummaryStateContentModel, HamiVideoCampaignDraftSummaryStateContentModel } from './DraftSummaryStateContentModel';

export abstract class DraftSummaryState implements ModalState {

  back?;
  next?;
  close?;

  constructor (
    protected l1Object: L1Object,
    protected l2ObjectsToCreateDraft: {
      id: number | string,
      isDraft: boolean
    } []
  ) {
    this.next = async () => {
      await this.contentModel.save();
      this.close && this.close(true);
    };
  }

  abstract get title ()

  titleFormatter = (title) => {
    if (i18n.language === 'en' && this.l2ObjectsToCreateDraft.length > 1) {
      return title = title + 's';
    }
    return title;
  }

  abstract get contentModel (): DefaultDraftSummaryStateContentModel;

  get content () {
    return DraftSummaryStateContent;
  }

  get btnData (): any {
    return {
      primaryButton: this.next && {
        title: i18n.t<string>('draftCreateModal.buttons.createDraft'),
        callback: this.next
      },
      secondaryButton: this.back && {
        title: i18n.t<string>('draftCreateModal.buttons.backToModify'),
        callback: this.back
      },
      dangerButton: this.close && {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: () => {
          this.close(false);
        }
      }
    };
  }

  canGoNext = () => {
    return this.contentModel.canGoNext;
  }
}

export class RtbCampaignDraftSummaryState extends DraftSummaryState {

  _contentModel = new RtbCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.RTB.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class FbAdSetDraftSummaryState extends DraftSummaryState {

  _contentModel = new FbAdSetDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.FB.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}
export class RetailCampaignDraftSummaryState extends DraftSummaryState {

  _contentModel = new RetailCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.RETAIL_MEDIA.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class EdiMaxCampaignDraftSummaryState extends DraftSummaryState {

  _contentModel = new EdiMaxCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.EDIMAX.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class PICCampaignDraftSummaryState extends DraftSummaryState {

  _contentModel = new PICCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.PIC.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}

export class HamiVideoCampaignDraftSummaryState extends DraftSummaryState {

  _contentModel = new HamiVideoCampaignDraftSummaryStateContentModel();

  get title () {
    return this.titleFormatter(i18n.t<string>('draftCreateModal.title', {
      l2Object: i18n.t<string>(`l2ObjectName.${L1ObjectChannel.HAMI_VIDEO.toLowerCase()}`),
      number: this.l2ObjectsToCreateDraft.length
    }));
  }

  get contentModel () {
    return this._contentModel;
  }
}
