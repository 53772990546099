import { RoleNames } from 'core';
import { AppMenuModel, DefaultAppMenuModel, DefaultAppMenuItemModel } from 'components/AppMenuBar';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { hasRoles, notRoles, addOnEnabled } from 'core/permission/PermissionDSL';
import { faChartBar, faCheckSquare, faBriefcase, faCubes, faCog } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

export default function defaultMenus (): Array<AppMenuModel> {

  const { sysAdmin, agencyAdmin, agencyReport, adsReport, agencyManager, agencySales, goJekAccountManager, fbAgencyManager, guest } = RoleNames;
  const reportMenuItems = [
    new DefaultAppMenuItemModel('appMenus.report.items.performance', '/reports/performance', notRoles(goJekAccountManager, guest)),
    new DefaultAppMenuItemModel('appMenus.report.items.campaignDashboard', '/reports/campaign-dashboard', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.REPORT.REPORT_CAMPAIGN_DASHBOARD)))),
    new DefaultAppMenuItemModel('appMenus.report.items.reconciliation', '/reports/reconciliation', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager, agencySales).and(addOnEnabled(ADDONFEATURE.COMPANY.AGENCY_PROVIDE_REPORT_TO_WORKFLOW)))),
    new DefaultAppMenuItemModel('appMenus.report.items.salesChannelReconciliation', '/reports/sales-channel-reconciliation', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.report.items.credit', '/reports/credits', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.report.items.storedValue', '/stored-value', hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager).and(addOnEnabled(ADDONFEATURE.COMPANY.STORED_VALUE))))
  ];
  const reportMenu = new DefaultAppMenuModel('appMenus.report.title', reportMenuItems, faChartBar);

  const companyMenuItems = [
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.agencies', '/agencies', hasRoles(sysAdmin, agencyAdmin, goJekAccountManager)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.advertisers', '/advertisers', hasRoles(sysAdmin, agencyAdmin, goJekAccountManager)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.oemAccounts', '/oem-accounts', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.accounts', '/accounts', hasRoles(sysAdmin, goJekAccountManager)),
    new DefaultAppMenuItemModel('appMenus.comapnyManagement.items.pmp', '/pmp', hasRoles(sysAdmin))
  ];
  const companyMenu = new DefaultAppMenuModel('appMenus.comapnyManagement.title', companyMenuItems, faBriefcase);

  const retailMaxMenuItems = [
    new DefaultAppMenuItemModel('appMenus.retailMax.items.productCategory', '/product-category', notRoles(sysAdmin, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.CHANNEL.RETAIL_MEDIA))),
    new DefaultAppMenuItemModel('appMenus.retailMax.items.productSet', '/product-sets', notRoles(sysAdmin, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.CHANNEL.RETAIL_MEDIA))),
    new DefaultAppMenuItemModel('appMenus.retailMax.items.productSegment', '/product-segments', notRoles(sysAdmin, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.CHANNEL.RETAIL_MEDIA)))
  ];

  const retailMaxMenu = new DefaultAppMenuModel('appMenus.retailMax.title', retailMaxMenuItems, faCheckSquare);

  const systemMenuItems = [
    new DefaultAppMenuItemModel('appMenus.system.items.productGroups', '/system/product-groups', hasRoles(sysAdmin, agencyAdmin, agencyManager)),
    // new DefaultAppMenuItemModel('appMenus.system.items.roles', '/system/roles', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.system.items.currencyRate', '/system/currency-rate', hasRoles(sysAdmin)),
    new DefaultAppMenuItemModel('appMenus.system.items.channelGroups', '/system/channel-groups', hasRoles(sysAdmin))
    // new DefaultAppMenuItemModel('appMenus.system.items.auditLog', '/system/audit-log', hasRoles(sysAdmin))
  ];
  const systemMenu = new DefaultAppMenuModel('appMenus.system.title', systemMenuItems, faCog);

  const advertisementMenuItems = [
    new DefaultAppMenuItemModel('appMenus.ads.items.orders', '/orders', notRoles(sysAdmin, adsReport, goJekAccountManager, guest)),
    new DefaultAppMenuItemModel('appMenus.ads.items.creatives', '/creatives', notRoles(sysAdmin, agencyReport, adsReport, goJekAccountManager, guest)),
    new DefaultAppMenuItemModel('appMenus.ads.items.onboarding', '/onboarding', hasRoles(fbAgencyManager)),
    // new DefaultAppMenuItemModel('appMenus.ads.items.creativeTemplates', '/ads/creative-templates', hasRoles(agencyAdmin, agencySales, adsAdmin).and(addOnEnabled(ADDONFEATURE.CREATIVE_STUDIO.DCS_TEMPLATES))),
    new DefaultAppMenuItemModel('appMenus.ads.items.conversion', '/conversions', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST))),
    new DefaultAppMenuItemModel('appMenus.ads.items.contactUs', '/contact-us', hasRoles(guest)),
    new DefaultAppMenuItemModel('appMenus.data.items.customLayouts', '/custom-layouts', hasRoles(sysAdmin))
  ];
  const advertisementMenu = new DefaultAppMenuModel('appMenus.ads.title', advertisementMenuItems, faCheckSquare);

  const dataMenuItems = [
    new DefaultAppMenuItemModel('appMenus.data.items.audienceSegments', '/audience-segments', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.ADSORDER.TENMAX))),
    new DefaultAppMenuItemModel('appMenus.data.items.gojekSegments', '/gojek-segments', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest).and(addOnEnabled(ADDONFEATURE.ADSORDER.GOJEK))),
    new DefaultAppMenuItemModel('appMenus.data.items.messageSegments', '/message-segments', notRoles(agencyReport, adsReport, goJekAccountManager, fbAgencyManager, guest).and(hasRoles(sysAdmin).or(addOnEnabled(ADDONFEATURE.CHANNEL.MESSAGE)))),
    new DefaultAppMenuItemModel('appMenus.data.items.tenmaxSegments', '/tenmax-segments/sources', hasRoles(sysAdmin))
  ];
  const dataMenu = new DefaultAppMenuModel('appMenus.data.title', dataMenuItems, faCubes);

  const menus = _.compact([
    advertisementMenu,
    retailMaxMenu,
    dataMenu,
    reportMenu,
    companyMenu,
    systemMenu
  ]);
  return menus;
}
